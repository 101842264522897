import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Data, AccordionIcon } from '../../data/AccordionData';
import { colors } from '../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userProfileFind } from '../../redux/profile/action';
import { commonFn } from '../../util/commonFn';

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${colors.transparent};
`;

const ULContainer = styled.ul`
  padding: 0px;
  list-style: none;

  .nav-link {
    color: ${colors.primary.black90};
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    display: block;
    vertical-align: middle;
    font-size: 15px;
    border-radius: 0.1875rem;
    position: relative;
    padding: 7px 0;
    outline-width: 0;
    cursor: pointer;
    text-decoration: none;
  }

  span {
    margin-right: 1.2rem;
  }

  .accordion-collapse-icon {
    position: absolute;
    right: 0;
    margin-top: 4px;
    font-size: 15px;
  }
  span.sidebar-menu-icon {
    vertical-align: -6px;
    margin-right: 10px !important;
  }

  .nav-item:hover,
  .nav-item.active {
    background-color: ${colors.sidebarbg};
  }
`;

const ListItem = styled.li`
  padding: 8px 12px;
  list-style: none;
  line-height: 0px;
`;

const SubmenuULContainer = styled.ul`
  color: ${colors.primary.black90};
  display: flex;
  padding: 0;
  flex-direction: column;
  p {
    font-size: 1rem;
  }
  .nav-item {
    padding-left: 34px;
  }
`;

export const Accordion = () => {
  const [clicked, setClicked] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const { userInfo } = useSelector(({ sign }) => ({
    ...sign,
  }));

  useEffect(() => {
    dispatch(userProfileFind());
  }, []);

  const user = userInfo?.data;

  let accordionData = Data;

  let hiddenKeys = [];

  // case: 2 based on the given permission
  const permissions = commonFn.permissions;

  if (user?.role_slug === 'user') {
    let clients = 0;
    let users = 0;
    let applicants = 0;
    let positions = 0;
    permissions.forEach((item) => {
      if (
        user?.permissions_slug?.indexOf(item.slug) === -1 &&
        hiddenKeys.indexOf(item.slug) === -1
      ) {
        hiddenKeys.push(item.slug);
        if (item.show === 'clients') {
          clients = clients + 1;
        }

        if (item.show === 'users') {
          users = users + 1;
        }

        if (item.show === 'applicants') {
          applicants = applicants + 1;
        }

        if (item.show === 'positions') {
          positions = positions + 1;
        }
      }
    });

    if (clients === 4) {
      hiddenKeys.push('clients');
    }

    if (users === 4) {
      hiddenKeys.push('users');
    }

    if (applicants === 4) {
      hiddenKeys.push('applicants');
    }

    if (positions === 4) {
      hiddenKeys.push('positions');
    }

    if (clients + users === 8) {
      hiddenKeys.push('admin');
    }

    if (positions + applicants === 8) {
      hiddenKeys.push('jobPortal');
    }
  }

  if (user && user?.type_slug === 'client' && user?.role_slug === 'admin') {
    hiddenKeys.push('delete-client');
    hiddenKeys.push('create-client');
    hiddenKeys.push('list-clients');
    hiddenKeys.push('edit-client');
    hiddenKeys.push('clients');
    hiddenKeys.push('hrms');
    hiddenKeys.push('accounts');
  }

  if (user && user?.type_slug === 'client' && user?.role_slug === 'user') {
    hiddenKeys.push('delete-client');
    hiddenKeys.push('create-client');
    hiddenKeys.push('list-clients');
    hiddenKeys.push('edit-client');
    hiddenKeys.push('clients');
    hiddenKeys.push('hrms');
    hiddenKeys.push('accounts');
    hiddenKeys.push('company');
  }

  if (user && user?.type_slug === 'super-admin' && user?.role_slug === 'admin') {
    hiddenKeys.push('company');
  }

  if (user && user?.type_slug === 'super-admin' && user?.role_slug === 'user') {
    hiddenKeys.push('company');
    hiddenKeys.push('clients');
    hiddenKeys.push('hrms');
    hiddenKeys.push('accounts');
  }

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  commonFn.menuHiddenKeys = hiddenKeys;
  return (
    <>
      {userInfo?.data && (
        <AccordionSection>
          <ULContainer className="sidebar-menu">
            {accordionData.map((item, index) => {
              if (hiddenKeys.indexOf(item.key) !== -1) {
                return null;
              }
              return (
                <React.Fragment key={`menu${index}`}>
                  {/* if menu has submenu */}
                  {item.submenu ? (
                    <ListItem className="nav-item" onClick={() => toggle(index)}>
                      <Link to={'#'} className={'nav-link'}>
                        <span className="sidebar-menu-icon">
                          <item.icon />
                        </span>
                        {item.menu}
                        <>
                          {clicked === index ? (
                            <AccordionIcon.minusIcon className="accordion-collapse-icon" />
                          ) : (
                            <AccordionIcon.plusIcon className="accordion-collapse-icon" />
                          )}
                        </>
                      </Link>
                    </ListItem>
                  ) : (
                    // if menu hasn't submenu
                    <ListItem
                      className={
                        'nav-item' + (item?.url.includes(location?.pathname) ? ' active' : '')
                      }
                      key={item.id}
                    >
                      <Link className={'nav-link'} to={item?.url}>
                        <span className="sidebar-menu-icon">
                          <item.icon />
                        </span>
                        {item.menu}
                      </Link>
                    </ListItem>
                  )}

                  {/* check if accordion */}
                  {clicked === index ? (
                    <SubmenuULContainer className="submenu">
                      {item.submenu.map((subitem, subindex) => {
                        if (hiddenKeys.indexOf(subitem.key) !== -1) {
                          return null;
                        }
                        return (
                          <ListItem
                            key={`submenu${subindex}`}
                            className={
                              'nav-item ' +
                              (location?.pathname.includes(subitem?.group) ? ' active subitem' : '')
                            }
                          >
                            <Link to={subitem?.url} className={'nav-link '}>
                              <span className="sidebar-menu-icon">
                                <subitem.icon />
                              </span>
                              {subitem.title}
                            </Link>
                          </ListItem>
                        );
                      })}
                    </SubmenuULContainer>
                  ) : null}
                </React.Fragment>
              );
            })}
          </ULContainer>
        </AccordionSection>
      )}
    </>
  );
};
