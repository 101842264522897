import React, { useEffect, useState } from 'react';
import {
  FormInputsData,
  FileUpload,
  ContractUpload,
  CompanyHrContractUpload,
} from '../../data/EditEmployeeData';
import { ViewFormCustom, TabCustom } from '../common';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { employeeFind } from '../../redux/hrms/action';
import { commonFn } from '../../util/commonFn';
import { Tabs, HrmsTabs } from '../../data/EmployeeData';
import { CircularProgress, Grid, TextField, Link, Box, FormControl } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export const ViewEmployee = ({ isEdit, isView, match, module }) => {
  const dispatch = useDispatch();
  const { push, history } = useHistory();
  const location = useLocation();
  const [hiddenKeys, setHiddenKeys] = useState([]);
  const [inputList, setInputList] = useState([]);

  const { employeeFindProcess, employeeFindError, employeeFindData } = useSelector(
    ({ employees }) => ({
      ...employees,
    }),
  );

  // employee find
  useEffect(() => {
    dispatch(employeeFind(match?.params?.id));
  }, []);

  let tabsData = [];

  if (location?.pathname.indexOf('hrms') !== -1) {
    module = 'hrms';
    tabsData = HrmsTabs;
  } else if (location?.pathname.indexOf('company') !== -1) {
    tabsData = Tabs;
    module = 'company';
  }

  // set hidden key in form inputs
  useEffect(() => {
    if (FormInputsData && module && module === 'company') {
      setHiddenKeys(() => ['client_id', 'hr_detail', 'account_detail']);
    }
  }, [module, FormInputsData]);

  // tabs
  const tabs = {
    ...tabsData,
    list: commonFn.updateLink(tabsData?.list, match),
  };

  //
  useEffect(() => {
    if (employeeFindData?.data?.document) {
      const list = [];
      employeeFindData?.data?.interview_status.map((item, index) => {
        list.push({
          statusValue: item.status,
          commentValue: item.comment,
          interviewDateValue: item.interview_date,
          commentLabel: `Comment ${index + 1}`,
          statusLabel: `Status ${index + 1}`,
          interviewDateLabel: `Interview Date ${index + 1}`,
        });
        setInputList(list);
      });
    }
  }, [employeeFindError, employeeFindData, employeeFindProcess]);

  //   button click
  const clickOnBtn = (value) => {
    if (value?.button?.key === 'edit') {
      if (module === 'hrms') {
        push(`/hrms/employees/edit/${match?.params?.id}`);
      } else if (module === 'company') {
        push(`/company/employees/edit/${match?.params?.id}`);
      }
    } else if (value?.button?.key === 'close') {
      if (module === 'hrms') {
        push(`/hrms/employees`);
      } else if (module === 'company') {
        push(`/company/employees`);
      }
    }
  };

  //  form data
  const formData = {
    list: FormInputsData,
    inProgress: employeeFindProcess,
    error: (!employeeFindData?.data && employeeFindData?.message) || employeeFindError?.message,
    isEdit,
    isView,
    value: employeeFindData?.data || {},
    tabs: tabs,
    groupLabelText: 'company_hr_detail',
    module: module,
  };

  // Upload Resume component
  const UploadResume = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid
            item
            xs={FileUpload?.xs || 12}
            sm={FileUpload?.sm || 12}
            md={FileUpload?.md || 6}
            lg={FileUpload?.lg || 4}
            xl={FileUpload?.xl || 4}
          >
            <Box className={FileUpload?.formElementClass}>
              <label className="interview-label">{FileUpload.label}</label>
              {formData.value?.resume && (
                <div className="file-section">
                  <a
                    href={formData.value?.resume}
                    target="_blank"
                    className={FileUpload.uploadTextClass}
                  >
                    <FileCopyIcon />
                    {formData.value?.resume_name}
                  </a>
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };
  // End upload resume

  // Upload Contract Component
  const UploadContract = () => {
    return (
      <>
        {/* <Grid container spacing={1}> */}
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Box className={ContractUpload?.formElementClass}>
            <label className="interview-label">{ContractUpload.label}</label>
            {formData.value?.contract && (
              <div className="file-section">
                <a
                  href={formData.value?.contract}
                  target="_blank"
                  className={ContractUpload.uploadTextClass}
                >
                  <FileCopyIcon />
                  {formData.value?.contract_name}
                </a>
              </div>
            )}
          </Box>
        </Grid>
        {/* </Grid> */}
      </>
    );
  };
  // End upload contract component

  // Upload Company HR Contract Component
  const UploadCompanyHrContract = () => {
    return (
      <>
        {/* <Grid container spacing={1}> */}
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Box className={CompanyHrContractUpload?.formElementClass}>
            <label className="interview-label">{CompanyHrContractUpload.label}</label>
            {formData.value?.company_hr_contract && (
              <div className="file-section">
                <a
                  href={formData.value?.company_hr_contract}
                  target="_blank"
                  className={CompanyHrContractUpload.uploadTextClass}
                >
                  <FileCopyIcon />
                  {formData.value?.company_hr_contract_name}
                </a>
              </div>
            )}
          </Box>
        </Grid>
        {/* </Grid> */}
      </>
    );
  };
  // End upload contract component

  // document/file upload
  const UploadDocuments = () => {
    return (
      <Grid container>
        <h4 className="interview-label form-input-box document-view-label">
          <strong>Document(s)</strong>
        </h4>
        {employeeFindData?.data?.documents?.map((list, inputListIndex) => (
          <Grid container spacing={1} key={inputListIndex}>
            <Grid
              item
              xs={list?.xs || 12}
              sm={list?.sm || 12}
              md={list?.md || 6}
              lg={list?.lg || 4}
              xl={list?.xl || 4}
            >
              <Box className="form-input-box">
                <label className="interview-label">{list.document_type}</label>
              </Box>
            </Grid>
            <Grid
              item
              xs={list?.xs || 12}
              sm={list?.sm || 12}
              md={list?.md || 6}
              lg={list?.lg || 4}
              xl={list?.xl || 4}
            >
              <Box className="form-input-box">
                {list?.document && (
                  <a href={list?.document} target="_blank">
                    {list.document_name}
                  </a>
                )}
              </Box>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <TabCustom {...tabs} />
      <ViewFormCustom
        {...formData}
        clickOnBtn={clickOnBtn}
        renderer={{ UploadResume, UploadContract, UploadDocuments, UploadCompanyHrContract }}
        hiddenKeys={hiddenKeys}
      />
    </>
  );
};
