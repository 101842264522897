import { Types } from './types';

const initState = {
  clientsFindAllProcess: false,
  clientsFindAllError: null,
  clientsFindAllData: null,

  createClientProcess: false,
  createClientError: null,
  createClientData: null,

  updateClientProcess: false,
  updateClientError: null,
  updateClientData: null,

  clientFindProcess: false,
  clientFindError: null,
  clientFindData: null,

  clientDeleteProcess: false,
  clientDeleteError: null,
  clientDeleteData: null,

  clientProfileFindProcess: false,
  clientProfileFindError: null,
  clientProfileFindData: null,

  updateClientProfileProcess: false,
  updateClientProfileError: null,
  updateClientProfileData: null,
};

export const clients = (state = initState, action) => {
  switch (action.type) {
    // Find all clients
    case Types.START_CLIENTS_FIND_ALL:
      return {
        ...state,
        clientsFindAllProcess: true,
        clientsFindAllError: null,
        clientsFindAllData: null,
        createClientError: null,
        createClientData: null,
      };
    case Types.END_CLIENTS_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        clientsFindAllProcess: false,
        clientsFindAllError: error,
        clientsFindAllData: success,
      };
    }

    // Create client
    case Types.START_CREATE_CLIENT:
      return {
        ...state,
        createClientProcess: true,
        createClientError: null,
        createClientData: null,
      };
    case Types.END_CREATE_CLIENT: {
      const { success, error } = action.payload;
      return {
        ...state,
        createClientProcess: false,
        createClientError: error,
        createClientData: success,
      };
    }

    // Update client
    case Types.START_UPDATE_CLIENT:
      return {
        ...state,
        updateClientProcess: true,
        updateClientError: null,
        updateClientData: null,
      };
    case Types.END_UPDATE_CLIENT: {
      const { success, error } = action.payload;
      return {
        ...state,
        updateClientProcess: false,
        updateClientError: error,
        updateClientData: success,
      };
    }

    // Find client
    case Types.START_CLIENT_FIND:
      return {
        ...state,
        clientFindProcess: true,
        clientFindError: null,
        clientFindData: null,
        updateClientProcess: false,
        updateClientError: null,
        updateClientData: null,
      };
    case Types.END_CLIENT_FIND: {
      const { success, error } = action.payload;
      return {
        ...state,
        clientFindProcess: false,
        clientFindError: error,
        clientFindData: success,
      };
    }

    // Delete client
    case Types.START_DELETE_CLIENT:
      return {
        ...state,
        clientDeleteProcess: true,
        clientDeleteError: null,
        clientDeleteData: null,
      };
    case Types.END_DELETE_CLIENT: {
      const { success, error } = action.payload;
      return {
        ...state,
        clientDeleteProcess: false,
        clientDeleteError: error,
        clientDeleteData: success,
      };
    }

    // Find client profile find
    case Types.START_CLIENT_PROFILE_FIND:
      return {
        ...state,
        clientProfileFindProcess: true,
        clientProfileFindError: null,
        clientProfileFindData: null,
      };
    case Types.END_CLIENT_PROFILE_FIND: {
      const { success, error } = action.payload;
      return {
        ...state,
        clientProfileFindProcess: false,
        clientProfileFindError: error,
        clientProfileFindData: success,
      };
    }

    // update client profile
    case Types.START_UPDATE_CLIENT_PROFILE:
      return {
        ...state,
        updateClientProfileProcess: true,
        updateClientProfileError: null,
        updateClientProfileData: null,
      };
    case Types.END_UPDATE_CLIENT_PROFILE: {
      const { success, error } = action.payload;
      return {
        ...state,
        updateClientProfileProcess: false,
        updateClientProfileError: error,
        updateClientProfileData: success,
      };
    }
    default:
      return state;
  }
};
