import React, { useEffect, useState } from 'react';
import { FormInputsData } from '../../../data/CreateClientData';
import { Tabs } from '../../../data/ClientsData';
import { ViewFormCustom, TabCustom } from '../../common';
import { commonFn } from '../../../util/commonFn';
import { clientFind } from '../../../redux/clients/action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Grid, TextField, Link, Box, FormControl } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export const ViewClient = ({ isEdit, isView, match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputList, setInputList] = useState([]);

  const { clientFindError, clientFindData, clientFindProcess } = useSelector(({ clients }) => ({
    ...clients,
  }));

  useEffect(() => {
    dispatch(clientFind(match?.params?.id));
  }, []);

  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  const formData = {
    list: FormInputsData,
    inProgress: clientFindProcess,
    error: (clientFindData?.data === null && clientFindData?.message) || clientFindError?.message,
    isEdit,
    isView,
    value: clientFindData?.data,
    tabs: tabs,
  };

  const clickOnBtn = (value) => {
    if (value?.button?.key === 'edit') {
      if (commonFn.menuHiddenKeys.indexOf('edit-client') !== -1) {
        commonFn.showPopup(commonFn.editAccessError);
        return false;
      }
      history.push(`/hrms/clients/edit/${match?.params?.id}`);
    } else if (value?.button?.key === 'close') {
      history.push(`/hrms/clients`);
    }
  };

  // document/file upload
  const UploadDocuments = () => {
    return (
      <Grid container>
        <h4 className="interview-label document-view-label form-input-box">
          <strong>Upload SLA(s)</strong>
        </h4>
        {clientFindData?.data?.documents?.map((list, inputListIndex) => (
          <Grid container spacing={1} key={inputListIndex}>
            <Grid
              item
              xs={list?.xs || 12}
              sm={list?.sm || 12}
              md={list?.md || 6}
              lg={list?.lg || 4}
              xl={list?.xl || 4}
            >
              <Box className="form-input-box">
                <label className="interview-label">{list.document_type}</label>
              </Box>
            </Grid>
            <Grid
              item
              xs={list?.xs || 12}
              sm={list?.sm || 12}
              md={list?.md || 6}
              lg={list?.lg || 4}
              xl={list?.xl || 4}
            >
              <Box className="form-input-box">
                {list?.document && <a href={list?.document} target='_blank'>{list.document_name}</a>}
              </Box>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <TabCustom {...tabs} />
      <ViewFormCustom {...formData} clickOnBtn={clickOnBtn} renderer={{ UploadDocuments }} />
    </>
  );
};

export default ViewClient;
