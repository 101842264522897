import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  FormControl,
  OutlinedInput,
  CircularProgress,
} from '@material-ui/core';
import './Sign.scss';
import {
  LoginFormInputs,
  ForgotPasswordInputs,
  ResetPasswordInputs,
  verifyInputs,
} from '../../data/RegisterFormData';
import { PrimaryButton } from '.';
import flipkoinsLogo from '../../assets/images/logo/flipkoins-logo.png';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  postLogin,
  postVerify,
  isAuth,
  postForgotPassword,
  postResetPassword,
} from '../../redux/sign/action';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/sign/action';

export const Sign = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const { replace, push } = useHistory();
  const {
    loginProcess,
    loginError,
    verifyProcess,
    verifyError,
    verifySuccess,
    loginSuccess,
    userInfo,
    isAuthicatedProcess,
    isAuthicatedError,
    forgotProcess,
    forgotError,
    forgotSuccess,
    resetProcess,
    resetError,
    resetSuccess,
  } = useSelector(({ sign }) => ({
    ...sign,
  }));

  const section = {
    title: 'Quark',
    isLogin: false,
    isForgotPassword: false,
    isResetPassword: false,
    isVerify: false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: 'all', reValidateMode: 'onChange' });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  let formInputs = {};

  switch (pathname) {
    case '/login':
      section.title = 'Quark - Login';
      section.isLogin = true;
      formInputs = LoginFormInputs;
      break;
    case '/forgot-password':
      section.title = 'Quark - Forgot Password';
      section.isForgotPassword = true;
      formInputs = ForgotPasswordInputs;
      break;
    case '/reset-password':
      section.title = 'Quark - Reset Password';
      section.isResetPassword = true;
      formInputs = ResetPasswordInputs;
      break;
    default:
      section.title = 'Quark - Verify Email';
      section.isVerify = true;
      formInputs = verifyInputs;
  }

  useEffect(() => {
    document.title = section.title;
    if (!userInfo) {
      dispatch(isAuth());
    }
  }, []);

  useEffect(() => {
    document.title = section.title;
    reset();
    setIsSubmitted(() => false);
    setError(() => null);
    setSuccess(() => null);
  }, [pathname]);

  useEffect(() => {
    if (!isAuthicatedProcess && userInfo && !isAuthicatedError && '/reset-password' !== pathname) {
      push('/positions');
    } else if (
      !isAuthicatedProcess &&
      userInfo &&
      !isAuthicatedError &&
      '/reset-password' === pathname
    ) {
      dispatch(logout());
    }
  }, [userInfo, isAuthicatedProcess, isAuthicatedError]);

  useEffect(() => {
    if (section.isLogin && isSubmitted) {
      setSuccess(() => null);
      setError(loginError?.message);
    }

    if (section.isVerify && isSubmitted) {
      setSuccess(() => null);
      setError(verifyError?.message);
    }

    if (section.isForgotPassword && isSubmitted) {
      setSuccess(() => null);
      setError(forgotError?.message);
    }

    if (section.isResetPassword && isSubmitted) {
      setSuccess(() => null);
      setError(resetError?.message);
    }

    if (forgotSuccess && section.isForgotPassword) {
      setSuccess(forgotSuccess?.message);
    }

    if (resetSuccess && section.isResetPassword) {
      setSuccess(resetSuccess?.message);
      setTimeout(() => {
        setSuccess(() => null);
        setIsSubmitted(() => false);
        replace('/login');
      }, 3000);
    }

    if (verifySuccess && section.isVerify) {
      setSuccess(verifySuccess?.message);
      setTimeout(() => {
        replace('/login');
        setSuccess(() => null);
      }, 2500);
    }

    if (loginSuccess && section.isLogin) {
      dispatch(isAuth());
    }
  }, [
    isSubmitted,
    loginError,
    loginSuccess,
    verifySuccess,
    forgotError,
    resetError,
    forgotSuccess,
    resetSuccess,
    verifyError,
  ]);

  useEffect(() => {
    if (section.isVerify) {
      setIsSubmitted(() => true);
      dispatch(postVerify(search));
    }
  }, [section.isVerify, dispatch, search]);

  const signForm = (data) => {
    setError(() => null);
    setSuccess(() => null);
    setIsSubmitted(() => true);
    if (pathname === '/login') {
      dispatch(postLogin(data));
    } else if (pathname === '/forgot-password') {
      dispatch(postForgotPassword(data));
    } else {
      dispatch(postResetPassword(data, search));
    }
  };

  return (
    <Container maxWidth="xl" className={formInputs?.className}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
          <form autoComplete="off" onSubmit={handleSubmit(signForm)}>
            <Card className={'card'}>
              <CardContent>
                {/* Flipkoins logo */}
                {!section?.isVerify && (
                  <Box mb={2} className={formInputs?.brand?.className}>
                    <img src={flipkoinsLogo} alt="Flipkoins Logo" />
                  </Box>
                )}

                {/* Card Header  */}
                <Box className={formInputs?.header?.className}>
                  <h3 className={formInputs?.header?.heading.className}>
                    {formInputs?.header?.heading.title}
                  </h3>
                  <Box mt={1}>
                    <p className={formInputs?.header?.subheading.className}>
                      {formInputs?.header?.subheading?.title}
                    </p>
                  </Box>
                </Box>

                {section.isVerify && verifyProcess && (
                  <div className="center-align">
                    <CircularProgress />
                  </div>
                )}

                {/* Form Inputs  */}
                <Box my={2}>
                  {formInputs?.data?.map((inputVal, index) => {
                    let inputRegister = register(inputVal?.name, {
                      required: inputVal?.isRequired,
                      pattern: inputVal.pattern,
                    });
                    return (
                      <Box className={inputVal?.formElementClass} key={index}>
                        <FormControl className={inputVal?.formControlClass}>
                          <OutlinedInput
                            className={inputVal?.fieldClass}
                            variant={inputVal?.variant}
                            margin={inputVal?.margin}
                            type={inputVal?.type}
                            name={inputVal?.name}
                            placeholder={inputVal?.placeholder}
                            {...inputRegister}
                            onChange={(e) => {
                              inputRegister.onChange(e);
                              setIsSubmitted(() => false);
                            }}
                            inputProps={{ maxLength: inputVal.max }}
                          />
                        </FormControl>

                        {inputVal?.errors?.map((error, index) => (
                          <span key={index}>
                            {errors[inputVal?.name]?.type === error?.type && (
                              <span className="error">{error?.message}</span>
                            )}
                          </span>
                        ))}
                      </Box>
                    );
                  })}

                  {/* response error message */}
                  <Box className={formInputs?.header?.className}>
                    {error && isSubmitted && <p className="error">{error}</p>}
                    {success && isSubmitted && <p className="success">{success}</p>}
                  </Box>
                </Box>

                {/* Button component */}
                {formInputs?.footer?.buttonText?.title && (
                  <Box className={formInputs?.header?.className}>
                    <Box display="flex" mt={4} justifyContent="space-between">
                      {!loginProcess && !forgotProcess && !resetProcess && (
                        <>
                          <PrimaryButton
                            type="submit"
                            text={formInputs?.footer?.buttonText?.title}
                          />
                          <Box pt={2}>
                            <Link to={formInputs?.footer?.link?.url}>
                              {formInputs?.footer?.link?.title}
                            </Link>
                          </Box>
                        </>
                      )}
                      {(loginProcess || forgotProcess || resetProcess) && <CircularProgress />}
                    </Box>
                  </Box>
                )}
              </CardContent>
            </Card>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};
