import { getService, postService, putService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

const startInvoiceFindAll = () => ({
  type: Types.START_FIND_ALL_INVOICES,
  payload: {},
});

const endInvoiceFindAll = (success, error) => ({
  type: Types.END_FIND_ALL_INVOICES,
  payload: {
    success,
    error,
  },
});

export const findAllInvoices =
  (skip = 0, limit = 10) =>
  async (dispatch) => {
    dispatch(startInvoiceFindAll());
    const [response, error] = await getService(`${apiList.invoices}?skip=${skip}&limit=${limit}`);
    dispatch(endInvoiceFindAll(response, error));
  };

// invoice find
const startFindInvoice = () => ({
  type: Types.START_FIND_INVOICE,
  payload: {},
});

const endFindInvoice = (success, error) => ({
  type: Types.END_FIND_INVOICE,
  payload: {
    success,
    error,
  },
});

export const invoiceFind = (id) => async (dispatch) => {
  dispatch(startFindInvoice());
  const [response, error] = await getService(`${apiList.invoices}/${id}`);
  dispatch(endFindInvoice(response, error));
};

// create invoice
const startCreateInvoice = () => ({
  type: Types.START_CREATE_INVOICE,
  payload: {},
});

const endCreateInvoice = (success, error) => ({
  type: Types.END_CREATE_INVOICE,
  payload: {
    success,
    error,
  },
});

export const createInvoice = (data) => async (dispatch) => {
  dispatch(startCreateInvoice());
  const [response, error] = await postService(apiList.invoices, data);
  dispatch(endCreateInvoice(response, error));
};

// update invoice
const startUpdateInvoice = () => ({
  type: Types.START_UPDATE_INVOICE,
  payload: {},
});

const endUpdateInvoice = (success, error) => ({
  type: Types.END_UPDATE_INVOICE,
  payload: {
    success,
    error,
  },
});

export const updateInvoice = (id, data) => async (dispatch) => {
  dispatch(startUpdateInvoice());
  const [response, error] = await putService(`${apiList.invoices}/${id}`, data);
  dispatch(endUpdateInvoice(response, error));
};

// send email invoice
const startSendInvoice = () => ({
  type: Types.START_SEND_EMAIL_INVOICE,
  payload: {},
});

const endSendInvoice = (success, error) => ({
  type: Types.END_SEND_EMAIL_INVOICE,
  payload: {
    success,
    error,
  },
});

export const sendInvoiceEmail = (data) => async (dispatch) => {
  dispatch(startSendInvoice());
  const [response, error] = await postService(`${apiList.sendInvoice}`, data);
  dispatch(endSendInvoice(response, error));
};
