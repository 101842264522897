import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Landing } from './Landing';
import { NotFound, Sign } from './common';

export const MainRouter = (props) => {
  return (
    <>
      <Switch>
        <Route exact path={'/login'} component={Sign} />
        <Route exact path={'/forgot-password'} component={Sign} />
        <Route exact path={'/reset-password'} component={Sign} />
        <Route exact path={'/verify'} component={Sign} />
        <Route path={'/'} component={Landing} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};
