import React from 'react';
import { Info, Edit } from '@mui/icons-material';
import { PrimaryButton } from '../common';
import { Box, Tooltip, TextareaAutosize, FormGroup } from '@material-ui/core';
import { HolidaysNoteData } from '../../data/HolidaysData';
import './HolidaysNote.scss';
import { useForm } from 'react-hook-form';

export const HolidaysNote = ({
  content,
  hiddenKeys,
  module,
  clickOnCell = () => {},
  actionCloseClick = () => {},
  formSubmit = () => {},
  isEdit = false,
}) => {
  const form = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = form;

  const closeBtnClick = (data) => {
    actionCloseClick(data);
  };

  return (
    <>
      <div className="holidays-note">
        {!isEdit && (
          <>
            <Info /> <p> {content?.note} </p>
          </>
        )}

        {!isEdit && module === 'hrms' && (
          <>
            <div className="action-container">
              <span
                className="icon-wrapper"
                onClick={(event) =>
                  clickOnCell({ action: 'edit', row: content, type: 'holidayNote' }, event)
                }
              >
                <Tooltip title={'Edit'} arrow>
                  <Edit className="icon-success action" />
                </Tooltip>
              </span>
            </div>
          </>
        )}
      </div>

      {/* holiday note edit  */}
      {isEdit && (
        <form onSubmit={handleSubmit(formSubmit)}>
          <div className="holidays-note edit-note">
            <Info />
            {HolidaysNoteData &&
              HolidaysNoteData?.inputs.map((input, index) => (
                <>
                  {input?.type === 'resizableTextArea' && (
                    <FormGroup className={'input-form-control'}>
                      <TextareaAutosize
                        placeholder={input?.placeholder}
                        className={input?.textAreaClass}
                        variant={input?.variant}
                        margin={input?.margin}
                        name={input?.name}
                        defaultValue={content?.note}
                        type={input?.type}
                        rows={input?.rows}
                        {...register(input?.name)}
                      />
                    </FormGroup>
                  )}

                  {input?.type === 'button' && (
                    <Box ml={5}>
                      <PrimaryButton text={input?.label} />
                      <Box mt={1}>
                        <span
                          className={'view-button'}
                          onClick={(e) => closeBtnClick({ e, action: 'close' })}
                        >
                          <PrimaryButton class={'view-close'} text={'Close'} />
                        </span>
                      </Box>
                    </Box>
                  )}
                </>
              ))}
          </div>
        </form>
      )}
    </>
  );
};
