import { postService, getService, putService, deleteService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// Clients Find All
const startClientsFindAll = () => ({
  type: Types.START_CLIENTS_FIND_ALL,
  payload: {},
});

const endClientsFindAll = (success, error) => ({
  type: Types.END_CLIENTS_FIND_ALL,
  payload: {
    success,
    error,
  },
});

export const clientsFindAll =
  (skip = 0, limit = 500) =>
  async (dispatch) => {
    dispatch(startClientsFindAll());
    const [response, error] = await getService(`${apiList.clients}?skip=${skip}&limit=${limit}`);
    dispatch(endClientsFindAll(response, error));
  };

// Create Client
const startCreateClient = () => ({
  type: Types.START_CREATE_CLIENT,
  payload: {},
});

const endCreateClient = (success, error) => ({
  type: Types.END_CREATE_CLIENT,
  payload: {
    success,
    error,
  },
});

export const createClient = (data) => async (dispatch) => {
  dispatch(startCreateClient());
  const [response, error] = await postService(apiList.clients, data);
  dispatch(endCreateClient(response, error));
};

// Find Client
const startClientFind = () => ({
  type: Types.START_CLIENT_FIND,
  payload: {},
});

export const endClientFind = (success = null, error = null) => ({
  type: Types.END_CLIENT_FIND,
  payload: {
    success,
    error,
  },
});

export const clientFind = (id) => async (dispatch) => {
  dispatch(startClientFind());
  const [response, error] = await getService(`${apiList.clients}/${id}`);
  dispatch(endClientFind(response, error));
};

// client profile find
const startClientProfileFind = () => ({
  type: Types.START_CLIENT_PROFILE_FIND,
  payload: {},
});

const endClientProfileFind = (success, error) => ({
  type: Types.END_CLIENT_PROFILE_FIND,
  payload: {
    success,
    error,
  },
});

export const clientProfileFind = (id) => async (dispatch) => {
  dispatch(startClientProfileFind());
  const [response, error] = await getService(`${apiList.clientProfile}/${id}`);
  dispatch(endClientProfileFind(response, error));
};

// Client Update
const startUpdateClient = () => ({
  type: Types.START_UPDATE_CLIENT,
  payload: {},
});

const endUpdateClient = (success, error) => ({
  type: Types.END_UPDATE_CLIENT,
  payload: {
    success,
    error,
  },
});

export const updateClient = (id, data) => async (dispatch) => {
  dispatch(startUpdateClient());
  const [response, error] = await putService(`${apiList.clients}/${id}`, data);
  dispatch(endUpdateClient(response, error));
};

// client profile update
const startUpdateClientProfile = () => ({
  type: Types.START_UPDATE_CLIENT_PROFILE,
  payload: {},
});

const endUpdateClientProfile = (success, error) => ({
  type: Types.END_UPDATE_CLIENT_PROFILE,
  payload: {
    success,
    error,
  },
});

export const updateClientProfile = (id, data) => async (dispatch) => {
  dispatch(startUpdateClientProfile());
  const [response, error] = await putService(`${apiList.clientProfile}/${id}`, data);
  dispatch(endUpdateClientProfile(response, error));
};

// Client Delete
const startDeleteClient = () => ({
  type: Types.START_DELETE_CLIENT,
  payload: {},
});

const endDeleteClient = (success, error) => ({
  type: Types.END_DELETE_CLIENT,
  payload: {
    success,
    error,
  },
});

export const deleteClient = (id) => async (dispatch) => {
  dispatch(startDeleteClient());
  const [response, error] = await deleteService(`${apiList.clients}/${id}`);
  dispatch(endDeleteClient(response, error));
};
