import { EditIcon, Info, Email, Print } from '@material-ui/icons';

// Applicants table data
export const ResponseData = [
  {
    id: 1,
    invoice_number: '1771',
    invoice_file: 'filename.pdf',
    invoice_date: '2021-10-12',
    invoice_status: 'Active ',
    amount: '50000',
  },
  {
    id: 2,
    invoice_number: '1772',
    invoice_file: 'filename.pdf',
    invoice_date: '2021-10-12',
    invoice_status: 'Active ',
    amount: '60000',
  },
  {
    id: 3,
    invoice_number: '1773',
    invoice_file: 'filename.pdf',
    invoice_date: '2021-10-12',
    invoice_status: 'Active ',
    amount: '70000',
  },
];

// Applicants table header
export const AccountsHead = [
  { label: 'id', key: 'id', hide: true, type: 'text', headClass: ' ', bodyClass: '' },
  {
    label: 'Invoice Number',
    key: 'invoice_number',
    hide: false,
    type: 'text',
    headClass: 'w-100',
    bodyClass: '',
  },
  {
    label: 'Company',
    key: 'company_logo',
    hiddenKey: 'company_logo',
    hide: false,
    type: 'file',
    headClass: 'w-80',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo',
  },
  {
    label: 'Invoice Attachment',
    key: 'invoice_attachment_name',
    hide: false,
    headClass: 'w-200',
    type: 'text',
    bodyClass: 'highlighted-link',
  },
  {
    label: 'Invoice Date',
    key: 'invoiced_date',
    hide: false,
    type: 'text',
    headClass: 'w-150',
    bodyClass: '',
  },
  {
    label: 'Status',
    key: 'invoice_status',
    hide: false,
    type: 'text',
    headClass: 'w-100',
    bodyClass: '',
  },
  {
    label: 'Amount',
    key: 'total_amount',
    hide: false,
    type: 'text',
    headClass: 'w-100',
    bodyClass: '',
  },
  {
    label: 'Created By',
    key: 'created_by_user_name',
    hiddenKey: 'created_by_user_name',
    hide: false,
    type: 'text',
    headClass: 'w-150',
    bodyClass: '',
  },
  {
    label: 'Action',
    key: 'actions',
    hide: false,
    type: 'actions',
    hiddenKey: 'actions',
    headClass: 'w-150 action-head',
    actions: [
      {
        bodyClass: 'icon-success',
        type: 'email',
        icon: Email,
        text: 'Send Invoice',
        click: '',
        url: '',
        actionClass: 'icon-wrapper',
      },
    ],
  },
  {
    label: 'Invoice Attachment URL',
    key: 'invoice_attachment',
    hide: true,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
];

// company accounts page header
export const PageHeader = {
  className: 'page-header',
  page_title: 'Accounts',
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Company', link: '/company/invoices', className: 'active' },
    { text: 'Accounts', link: '', className: 'active' },
  ],
};

// Accounts Invoice page header - admin
export const InvoicePageHeader = {
  className: 'page-header',
  page_title: 'Accounts',
  button: {
    text: 'Create Invoice',
    className: 'link-button btn-sm btn-primary',
    url: '/accounts/invoices/create',
    hiddenKey: 'create-invoice',
  },
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Accounts', link: '/accounts/invoices', className: 'active' },
    { text: 'Invoices', link: '', className: 'active' },
  ],
};

// Tabs data
export const Tabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Invoices',
      key: 'invoices',
      link: '/company/invoices',
      className: 'tab-link',
      hiddenKey: 'list-invoices',
    },
    {
      label: 'Edit Invoice',
      key: 'edit',
      link: '/company/invoices/edit/:id',
      path: '/company/invoices/edit/:id',
      className: 'tab-link',
      hiddenKey: 'edit-invoice',
    },
    {
      label: 'View Invoice',
      key: 'view',
      link: '/company/invoices/view/:id',
      path: '/company/invoices/view/:id',
      className: 'tab-link',
      hiddenKey: 'list-invoices',
    },
  ],
};

// invoice tabs data
export const InvoiceTabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Create Invoice',
      key: 'create',
      link: '/accounts/invoices/create',
      path: '/accounts/invoices/create',
      className: 'tab-link',
      hiddenKey: 'create-invoice',
    },
    {
      label: 'Edit Invoice',
      key: 'edit',
      link: '/accounts/invoices/edit/:id',
      path: '/accounts/invoices/edit/:id',
      className: 'tab-link',
      hiddenKey: 'edit-invoice',
    },
    {
      label: 'View Invoice',
      key: 'view',
      link: '/accounts/invoices/view/:id',
      path: '/accounts/invoices/view/:id',
      className: 'tab-link',
      hiddenKey: 'list-invoices',
    },
    {
      label: 'Invoices',
      key: 'invoices',
      link: '/accounts/invoices',
      className: 'tab-link',
      hiddenKey: 'list-invoices',
    },
  ],
};

// send invoice form data
export const SendInvoiceFormData = {
  groupLabel: 'Send Invoice',
  groupLabelClass: 'fieldset-label',
  key: 'send-invoice',
  groupClass: 'form-fieldset',
  inputs: [
    {
      label: 'To',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      key: 'billing_email',
      name: 'billing_email',
      displayKey: 'billing_email',
      placeholder: 'Select Billing Email',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'multiSelectCreate', //multiSelectCreate
      optionsKey: 'billingEmail',
      valueId: 'billing_email',
      fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
      options: [],
      size: 'small',
      isRequired: true,
      errors: [{ type: 'required', message: 'Select or create billing email' }],
    },
    {
      label: 'CC',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      key: 'cc_email',
      name: 'cc_email',
      displayKey: 'cc_email',
      placeholder: 'Select Billing Email',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'multiSelectCreate', //multiSelectCreate
      optionsKey: 'ccEmail',
      valueId: 'cc_email',
      fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
      options: [],
      size: 'small',
      isRequired: true,
      errors: [{ type: 'required', message: 'Select or create billing email' }],
    },
    // {
    //   label: 'Body (Message)',
    //   xs: 12,
    //   sm: 12,
    //   md: 12,
    //   lg: 12,
    //   xl: 12,
    //   key: 'message',
    //   name: 'message',
    //   placeholder: 'Body (Message)',
    //   hide: false,
    //   formControlClass: 'input-form-control',
    //   type: 'resizableTextArea',
    //   fieldClass: 'text-box-style',
    //   textAreaClass: 'resizableTextArea',
    //   variant: 'outlined',
    //   margin: 'dense',
    //   formElementClass: 'form-input-box',
    //   isRequired: true,
    //   rows: 4,
    //   errors: [{ type: 'required', message: 'Please enter message' }],
    // },
  ],
};
