import { Types } from './types';

const initState = {
  employeeCreateProcess: false,
  employeeCreateError: null,
  employeeCreateData: null,

  employeeFindAllProcess: false,
  employeeFindAllError: null,
  employeeFindAllData: null,

  employeeFindProcess: false,
  employeeFindError: null,
  employeeFindData: null,

  employeeUpdateProcess: false,
  employeeUpdateError: null,
  employeeUpdateData: null,
};

export const employees = (state = initState, action) => {
  switch (action.type) {
    // employee create
    case Types.START_CREATE_EMPLOYEE:
      return {
        ...state,
        employeeCreateProcess: true,
        employeeCreateError: null,
        employeeCreateData: null,
      };
    case Types.END_CREATE_EMPLOYEE: {
      const { success, error } = action.payload;
      return {
        ...state,
        employeeCreateProcess: false,
        employeeCreateError: error,
        employeeCreateData: success,
      };
    }

    // employee find all
    case Types.START_EMPLOYEE_FIND_ALL:
      return {
        ...state,
        employeeFindAllProcess: true,
        employeeFindAllError: null,
        employeeFindAllData: null,
      };
    case Types.END_EMPLOYEE_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        employeeFindAllProcess: false,
        employeeFindAllError: error,
        employeeFindAllData: success,
      };
    }

    // employee find
    case Types.START_EMPLOYEE_FIND:
      return {
        ...state,
        employeeFindProcess: true,
        employeeFindError: null,
        employeeFindData: null,
      };
    case Types.END_EMPLOYEE_FIND: {
      const { success, error } = action.payload;
      return {
        ...state,
        employeeFindProcess: false,
        employeeFindError: error,
        employeeFindData: success,
      };
    }

    // employee update
    case Types.START_EMPLOYEE_UPDATE:
      return {
        ...state,
        employeeUpdateProcess: true,
        employeeUpdateError: null,
        employeeUpdateData: null,
      };
    case Types.END_EMPLOYEE_UPDATE: {
      const { success, error } = action.payload;
      return {
        ...state,
        employeeUpdateProcess: false,
        employeeUpdateError: error,
        employeeUpdateData: success,
      };
    }
    default:
      return state;
  }
};
