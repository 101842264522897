import React, { useState, useEffect } from 'react';
import { AddViewEditLeave } from '../../common';

export const EditLeave = () => {
  const childProps = {
    isEdit: true,
    hiddenKey: [],
    readOnly: [],
    url: [],
  };

  return (
    <>
      <AddViewEditLeave {...childProps} />
    </>
  );
};
