import React, { useEffect, useState } from 'react';
import { FormInputsData } from '../../data/CreateUserData';
import { Tabs } from '../../data/UsersData';
import { FormCustom, TabCustom } from '../common';
import { commonFn } from '../../util/commonFn';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { permissionsFindAll } from '../../redux/master/action';
import { clientsFindAll } from '../../redux/clients/action';
import { CircularProgress } from '@material-ui/core';
import { createUser, findUser, updateUser } from '../../redux/users/action';
import { openSnackBarLayout } from '../../redux/common/action';
import { userTypesFind, userRolesFind } from '../../redux/user_types/action';
import axios from 'axios';
import { apiList } from '../../util/apiList';
import { Permissions } from '.';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const CreateUser = ({ isEdit, isView, match }) => {
  const MySwal = withReactContent(Swal);

  // create user state
  const {
    createUserProcess,
    createUserError,
    createUserData,
    userFindProcess,
    userFindError,
    userFindData,
    updateUserProcess,
    updateUserError,
    updateUserData,
    clientsFindAllProcess,
    clientsFindAllError,
    clientsFindAllData,
    permissionsFindAllData,
    userTypesFindProcess,
    userTypesFindError,
    userTypesFindData,
    userRolesFindProcess,
    userRolesFindError,
    userRolesFindData,
    userInfo,
  } = useSelector(({ users, clients, permissions, userTypesNew, sign }) => ({
    ...users,
    ...clients,
    ...permissions,
    ...userTypesNew,
    ...sign,
  }));

  const dispatch = useDispatch();
  const { push } = useHistory();

  const [limit, skip] = '';

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isRouteChange, setIsRouteChange] = useState(false);
  const [hiddenKeys, setHiddenKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    list: FormInputsData,
    isEdit,
    isView,
    values: {},
    error: '',
    inProgress: isEdit ? userFindProcess : false,
    options: {},
  });

  // mount
  useEffect(() => {
    setFormData({
      list: FormInputsData,
      isEdit,
      isView,
      values: {},
      error: '',
      inProgress: isEdit ? true : false,
      options: {},
    });
    setIsRouteChange(true);
    dispatch(clientsFindAll(skip, limit));
    dispatch(permissionsFindAll());
    match?.params?.id && dispatch(findUser(match?.params?.id));
    dispatch(userTypesFind());
    dispatch(userRolesFind());
    setTimeout(() => setIsRouteChange(false), 50);
    if (isEdit) {
      setHiddenKeys(() => ['password']);
    } else {
      setHiddenKeys(() => []);
    }
  }, [match?.params?.id]);

  // company name
  useEffect(() => {
    if (!clientsFindAllProcess && !clientsFindAllError && clientsFindAllData) {
      const clients =
        (clientsFindAllData &&
          clientsFindAllData?.data?.list?.map((client) => {
            return { label: client?.company_name, value: client?.id };
          })) ||
        [];

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && userFindData?.data
            ? {
                ...data.values,
                company_id: commonFn.getMultiValueFromId(
                  userFindData?.data?.company_id,
                  clients,
                )[0],
              }
            : data.values,
        options: { ...data.options, clients },
      }));
    }
  }, [clientsFindAllProcess, clientsFindAllError, clientsFindAllData, userFindData]);

  // User Types
  useEffect(() => {
    if (!userTypesFindProcess && !userTypesFindError && userTypesFindData) {
      const userTypes =
        (userTypesFindData &&
          userTypesFindData?.data?.list?.map((userType) => {
            return { label: userType?.user_type, value: userType?.slug, slug: userType?.slug };
          })) ||
        [];

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && userFindData?.data
            ? {
                ...data.values,
                type_slug: commonFn.getMultiValueFromId(
                  userFindData?.data?.type_slug,
                  userTypes,
                )[0],
              }
            : data.values,
        options: { ...data.options, userTypes },
      }));
    }
  }, [userTypesFindProcess, userTypesFindError, userTypesFindData, userFindData]);

  //User Roles
  useEffect(() => {
    if (!userRolesFindProcess && !userRolesFindError && userRolesFindData) {
      const userRoles =
        (userRolesFindData &&
          userRolesFindData?.data.list?.map((userRole) => {
            return { label: userRole?.role, value: userRole?.slug, slug: userRole?.slug };
          })) ||
        [];

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && userFindData?.data
            ? {
                ...data.values,
                role_slug: commonFn.getMultiValueFromId(
                  userFindData?.data?.role_slug,
                  userRoles,
                )[0],
              }
            : data.values,
        options: { ...data.options, userRoles },
      }));
    }
  }, [userRolesFindProcess, userRolesFindError, userRolesFindData, userFindData]);

  const loggedInUser = () => {
    MySwal.fire({
      title: 'Warning!',
      text: `You can't update your information here. Please use Profile section to update it`,
      icon: 'warning',
      focusConfirm: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Go to user list',
    }).then((result) => {
      push(`/users`);
    });
  };

  // User find
  useEffect(() => {
    if (isEdit) {
      if (!userFindProcess && !userFindError && userFindData?.data) {
        let permission = [];

        if (
          permissionsFindAllData?.data?.list?.length &&
          userFindData?.data?.role_slug === 'user'
        ) {
          if (userFindData?.data?.permissions_slug) {
            const permissionIds = userFindData?.data?.permissions_slug?.split(',');
            permissionsFindAllData.data.list.forEach((item) => {
              permission[item.slug] = permissionIds.indexOf(item.slug.toString()) > -1;
            });
          }
        }

        if (userFindData?.data?.role_slug === 'admin') {
          setHiddenKeys((data) => [...data, 'permissions']);
        }

        setFormData((data) => ({
          ...data,
          values: { ...userFindData?.data, ...data?.values, permission },
          inProgress: false,
        }));

        setTimeout(() => {
          if (userFindData.data?.id === userInfo?.data?.id) {
            loggedInUser();
          }
        }, 500);
      } else if (!userFindProcess && (userFindError?.message || userFindData?.message)) {
        const error = userFindData?.message || userFindError?.message;
        setFormData((data) => ({ ...data, error, inProgress: false }));
      }
    }
  }, [userFindProcess, userFindError, userFindData, permissionsFindAllData, userInfo]);

  // tabs data
  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  // create user success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!createUserProcess && !createUserError && createUserData?.data) {
        dispatch(openSnackBarLayout(createUserData?.message, 'success', 2000));
        setIsLoading(() => false);
        push(`/users/view/${createUserData?.data?.id}`);
      } else if (createUserError?.message && !updateUserProcess) {
        setIsLoading(() => false);
        dispatch(openSnackBarLayout(createUserError?.message, 'error', 2000));
      }
    }
  }, [createUserProcess, createUserError, createUserData]);

  // update user success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!updateUserProcess && !updateUserError && updateUserData?.data) {
        dispatch(openSnackBarLayout(updateUserData?.message, 'success', 2000));
        setIsLoading(() => false);
        push(`/users/view/${updateUserData?.data?.id}`);
      } else if (updateUserError?.message && !updateUserProcess) {
        setIsLoading(() => false);
        dispatch(openSnackBarLayout(updateUserError?.message, 'error', 2000));
      }
    }
  }, [updateUserProcess, updateUserError, updateUserData]);

  // file upload event
  const changeEvent = async (data) => {
    if (
      data.field?.name === 'profile_image' &&
      data?.value?.target?.files &&
      data.value.target.files?.length > 0
    ) {
      const file = data?.value?.target?.files[0];
      var formData = new FormData();
      formData.append('folderName', 'user');
      formData.append('file', file);
      setFormData((data) => ({ ...data, inProgress: true }));

      const response = await axios.post(apiList.fileUpload, formData, {
        headers: {},
        withCredentials: true,
      });

      const result = response?.data || {};

      if (result?.data) {
        setFormData((form) => ({
          ...form,
          values: {
            ...form.values,
            ...data.watch,
            profile_image: result?.data?.uploaded_url,
            original_name: result?.data?.original_name,
          },
          inProgress: false,
        }));
      } else {
        dispatch(openSnackBarLayout('User profile image is not uploaded', 'error', 1000));
      }
    }

    if (data.field?.name === 'remove') {
      setFormData((form) => ({
        ...form,
        values: { ...form.values, ...data.watch, profile_image: '' },
      }));
    }

    if (data.field.name === 'type_slug' && data.value?.value) {
      setFormData((formdata) => ({
        ...formdata,
        values: {
          profile_image: formdata.values?.profile_image,
          ...formdata.watch,
          // permission: [],
        },
      }));
    }

    if (data.field.name === 'role_slug' && data.value?.value) {
      setFormData((formdata) => ({
        ...formdata,
        values: {
          profile_image: formdata.values?.profile_image,
          ...formdata.watch,
          role_slug: data.value,
          // permission: [],
        },
      }));

      if (data.value?.value === 'admin') {
        setHiddenKeys((data) => [...data, 'permissions']);
      } else {
        setHiddenKeys((data) => data.filter((item) => item !== 'permissions'));
      }
    }
  };

  // form submit action
  const formSubmit = (data) => {
    const request = data;
    setIsLoading(() => true);
    request.company_id = data?.company_id?.value || data?.company_id || '';
    request.type_slug = data?.type_slug?.value || data?.type_slug || '';
    request.role_slug = data?.role_slug?.value || data?.role_slug || '';

    let permissions = [];

    if (request.permission) {
      for (let item in request.permission) {
        const value = request.permission[item];
        if (value) {
          if (request.type_slug === 'client' && request.role_slug === 'user') {
            if (
              item.indexOf('create-client') === -1 &&
              item.indexOf('list-clients') === -1 &&
              item.indexOf('edit-client') === -1 &&
              item.indexOf('delete-client') === -1
            ) {
              permissions.push(item);
            }
          } else {
            permissions.push(item);
          }
        }
      }
    }
    setIsFormSubmitted(true);

    if (request.role_slug === 'admin') {
      permissions = [];
    }

    request.permissions_slug = permissions.toString();
    data.profile_image = formData?.values?.profile_image || '';

    if (isEdit) {
      if (userFindData?.data?.id === userInfo?.data?.id) {
        loggedInUser();
      } else {
        dispatch(updateUser(match?.params?.id, data));
      }
    } else {
      dispatch(createUser(data));
    }
  };

  const PermissionRenderer = (form) => {
    return (
      <>
        {permissionsFindAllData?.data?.list?.length && (
          <Permissions {...{ permissionsFindAllData, form, formData }} />
        )}
      </>
    );
  };

  return (
    <>
      <TabCustom {...tabs} />

      {!isRouteChange && (
        <FormCustom
          {...formData}
          formSubmit={formSubmit}
          changeEvent={changeEvent}
          renderer={{ Permissions: PermissionRenderer }}
          hiddenKeys={hiddenKeys}
          isLoading={isLoading}
        />
      )}
      {isRouteChange && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default CreateUser;
