import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

// Applicants table data
export const ResponseData = [
  {
    id: 1,
    profile_avatar: 'JD',
    first_name: 'John',
    last_name: 'Doe',
    full_name: 'John Doe',
    company_name: 'Flipkoins',
    email: 'johndoe@test.com',
    designation: 'HR Manager',
  },
];

// Applicants table header
export const PositionHead = [
  { label: 'id', key: 'id', hide: true, type: 'text', headClass: ' ', bodyClass: '' },
  {
    label: 'Avatar',
    key: 'profile_image',
    name: 'profile_image',
    hide: false,
    type: 'file',
    headClass: 'w-80',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo profile-image',
  },
  { label: 'Name', key: 'name', hide: false, headClass: 'w-150', type: 'text' },
  {
    label: 'Company',
    key: 'company_logo',
    hide: false,
    hiddenKey: 'company_logo',
    type: 'file',
    headClass: 'w-150',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo',
  },
  { label: 'Email', key: 'email', hide: false, type: 'text', headClass: 'w-200', bodyClass: '' },
  {
    label: 'User Type',
    key: 'user_type',
    hide: false,
    type: 'text',
    headClass: 'w-150',
    bodyClass: '',
  },
  {
    label: 'User Role',
    key: 'user_role',
    hide: false,
    type: 'text',
    headClass: 'w-150',
    bodyClass: '',
  },
  {
    label: 'Designation',
    key: 'designation',
    hide: false,
    type: 'text',
    headClass: 'w-150',
    bodyClass: 'badge-outlined badge-success',
  },
  {
    label: 'Action',
    key: 'actions',
    hide: false,
    type: 'actions',
    headClass: 'w-150 action-head',
    hiddenKey: 'action',
    actions: [
      {
        bodyClass: 'icon-success',
        type: 'edit',
        icon: EditIcon,
        text: 'Edit',
        url: '',
        actionClass: 'icon-wrapper',
      },
      {
        bodyClass: 'icon-danger',
        type: 'delete',
        icon: DeleteIcon,
        text: 'Delete',
        url: '',
        actionClass: 'icon-wrapper',
      },
    ],
  },
];

// page header, title, breadcrumb and button
export const PageHeader = {
  className: 'page-header',
  page_title: 'User Management',
  button: {
    text: 'Create User',
    className: 'link-button btn-sm btn-primary',
    hiddenKey: 'create-user',
    url: '/users/create',
  },
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Users', link: '', className: 'active' },
  ],
};

// Tabs data
export const Tabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Create User',
      key: 'create',
      link: '/users/create',
      hiddenKey: 'create-user',
      path: '/users/create',
      className: 'tab-link',
    },
    {
      label: 'Edit User',
      key: 'edit',
      hiddenKey: 'edit-user',
      link: '/users/edit/:id',
      path: '/users/edit/:id',
      className: 'tab-link',
    },
    {
      label: 'View User',
      key: 'view',
      link: '/users/view/:id',
      path: '/users/view/:id',
      hiddenKey: 'list-users',
      className: 'tab-link',
    },
    {
      label: 'Users',
      hiddenKey: 'list-users',
      key: 'users',
      link: '/users',
      className: 'tab-link',
    },
  ],
};
