import { PhotoCamera } from '@material-ui/icons';
import { Validation } from './CommonData';

export const FormInputsData = [
  {
    groupLabel: 'Company Data',
    groupLabelClass: 'fieldset-label',
    key: 'company',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Company Logo',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'avatar',
        placeholder: '',
        hide: false,
        formControlClass: 'input-form-control file-upload-control',
        type: 'file',
        imageClass: 'form-control-image display-avatar-logo',
        displayImageClass: 'display-avatar-logo',
        name: 'company_logo',
        fieldClass: 'input-file-button',
        id: 'company-avatar-upload',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box file-upload-element',
        icon: PhotoCamera,
        accept: 'image/*',
        buttonText: 'Change',
      },
      {
        label: 'Company Name',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'company_name',
        name: 'company_name',
        placeholder: 'Company Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 3,
        maxLength: 100,
        errors: [
          { type: 'required', message: 'Please enter company name' },
          { type: 'minLength', message: 'Please enter atleast three characters' },
        ],
      },
      {
        label: 'Primary Email',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'email',
        name: 'primary_email',
        placeholder: 'Primary Email',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        pattern: Validation.email,
        maxLength: 100,
        errors: [
          { type: 'required', message: 'Please enter a primary email' },
          { type: 'pattern', message: 'Please enter a valid email' },
        ],
      },
      {
        label: 'Phone Number',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'phone',
        name: 'phone',
        placeholder: 'Phone Number',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        pattern: Validation.numberAndPlus,
        maxLength: 20,
        minLength: 5,
        errors: [
          { type: 'pattern', message: 'Please enter a valid phone number' },
          { type: 'minLength', message: 'Phone number must be greater than 5 digits' },
        ],
      },
      {
        label: 'Website',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'website',
        name: 'website',
        placeholder: 'Website',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        pattern: Validation.website,
        maxLength: 100,
        errors: [
          { type: 'required', message: 'Please enter the company website url' },
          { type: 'pattern', message: 'Please enter a valid url' },
        ],
      },
      {
        label: 'LinkedIn',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'linkedin',
        name: 'linkedin',
        placeholder: 'LinkedIn',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Industry',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'industry',
        name: 'industry',
        placeholder: 'Industry',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        maxLength: 100,
      },
      {
        label: 'Number of Employee',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'employee',
        name: 'total_employees',
        placeholder: 'No. of Employee',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        pattern: Validation.numberOnly,
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        errors: [{ type: 'pattern', message: 'Please enter numerical value' }],
      },
    ],
  },
  {
    groupLabel: 'Company Address',
    groupLabelClass: 'fieldset-label',
    key: 'address',
    name: 'address',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Address',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'address',
        name: 'address',
        placeholder: 'Address',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Please enter the company address' }],
      },
      {
        label: 'City',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'city_id',
        name: 'city_id',
        placeholder: 'Select City',
        hide: false,
        optionsKey: 'city',
        valueId: 'city_id',
        displayKey: 'city',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Select or create the city' }],
      },
      {
        label: 'Zip Code',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'zipcode',
        name: 'zipcode',
        placeholder: 'Zip Code',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 3,
        maxLength: 10,
        errors: [
          {
            type: 'required',
            message: 'Please enter the zipcode',
          },
          {
            type: 'pattern',
            message: 'Please enter a valid zipcode',
          },
          {
            type: 'minLength',
            message: 'Please enter atleast three characters',
          },
        ],
      },
      {
        label: 'State',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'state_id',
        name: 'state_id',
        placeholder: 'Select State',
        hide: false,
        optionsKey: 'state',
        valueId: 'state_id',
        displayKey: 'state',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Select or create the state' }],
      },
      {
        label: 'Country',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'country_id',
        name: 'country_id',
        placeholder: 'Select Country',
        hide: false,
        optionsKey: 'country',
        valueId: 'country_id',
        displayKey: 'country',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Select or create the country' }],
      },
      {
        label: 'Timezone',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'timezone_id',
        name: 'timezone_id',
        placeholder: 'Select Timezone',
        hide: false,
        optionsKey: 'timezones',
        valueId: 'timezone_id',
        displayKey: 'timezone',
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'multiselect textBoxStyle',
        options: [],
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
    ],
  },
  {
    groupLabel: 'Billing Address',
    groupLabelClass: 'fieldset-label',
    key: 'billing',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Billing Email',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'billing_email',
        name: 'billing_email',
        placeholder: 'Billing Email',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        pattern: Validation.email,
        maxLength: 100,
        errors: [
          {
            type: 'required',
            message: 'Please enter the billing email',
          },
          {
            type: 'pattern',
            message: 'Please enter a valid email',
          },
        ],
      },
      {
        label: 'GSTIN',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'gstin',
        name: 'gstin',
        placeholder: 'GSTIN',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        maxLength: 100,
      },
      {
        label: 'Address',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'address',
        name: 'billing_address',
        placeholder: 'Address',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        errors: [
          {
            type: 'required',
            message: 'Please enter the billing address',
          },
        ],
      },
      {
        label: 'City',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'billing_city_id',
        name: 'billing_city_id',
        placeholder: 'Select City',
        hide: false,
        optionsKey: 'billing_city',
        valueId: 'billing_city_id',
        displayKey: 'billing_city',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Select or create the city' }],
      },
      {
        label: 'Zip Code',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'billing_zipcode',
        name: 'billing_zipcode',
        placeholder: 'Zip Code',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 3,
        maxLength: 10,
        errors: [
          {
            type: 'required',
            message: 'Please enter the zipcode',
          },
          {
            type: 'pattern',
            message: 'Please enter a valid zipcode',
          },
          {
            type: 'minLength',
            message: 'Please enter atleast three characters',
          },
        ],
      },
      {
        label: 'State',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'billing_state_id',
        name: 'billing_state_id',
        placeholder: 'Select State',
        hide: false,
        optionsKey: 'billing_state',
        valueId: 'billing_state_id',
        displayKey: 'billing_state',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Select or create the state' }],
      },
      {
        label: 'Country',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'billing_country_id',
        name: 'billing_country_id',
        placeholder: 'Select Country',
        hide: false,
        optionsKey: 'billing_country',
        valueId: 'billing_country_id',
        displayKey: 'billing_country',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Select or create the country' }],
      },
      {
        type: 'renderer',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'UploadDocuments',
      },
    ],
  },
 
];

// document upload
export const DocumentUploadInputs = {
  label: 'Upload SLA',
  displayLabel: 'Upload Documents',
  containerClass: 'interview_status_row',
  xs: 12,
  sm: 12,
  md: 12,
  lg: 3,
  xl: 3,
  documentTypeName: 'documentList.0.document_type',
  documentName: 'documentList.0.document',
  documentUploadType: 'file',
  documentUploadId: 'document-0-value',
  uploadTextClass: 'file-label',
  formControlClass: 'input-form-control',
  fieldClass: 'text-box-style',
  documentTypePlaceholder: 'Select Document Type',
  documentPlaceholder: 'Upload Document',
  variant: 'outlined',
  margin: 'dense',
  inputClass: 'input-file-button',
  keyValue: 1,
  formElementClass: 'form-input-box',
};

// page header, title, breadcrumb and button
export const PageHeader = {
  className: 'page-header',
  page_title: 'Profile',
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Company', link: '/company/profile', className: '' },
    { text: 'Profile', link: '', className: 'active' },
  ],
};

// company profile tab
export const ProfileTabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'View Profile',
      key: 'view',
      link: '/company/profile',
      path: '/company/profile',
      className: 'tab-link',
      hiddenKey: 'view-profile',
    },
    {
      label: 'Edit Profile',
      key: 'edit',
      link: '/company/profile/edit',
      path: '/company/profile/edit',
      className: 'tab-link',
      hiddenKey: 'edit-profile',
    },
  ],
};
