import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { colors } from '../../theme';
import { NavItems } from '../../data/HeaderData';
import { Link } from 'react-router-dom';
import defaultProfileImage from '../../assets/images/default-profile-new.png';
import { logout } from '../../redux/sign/action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userProfileFind } from '../../redux/profile/action';
import { Menu } from '@mui/icons-material';
import { NavBrand } from '../../data/HeaderData';
import { openSidebar } from '../../redux/common/action';

const NavIconWrapper = styled.span`
  vertical-align: -6px;
  margin-right: 10px;
`;

const DropdownButton = styled.a`
  border: none;
  cursor: pointer;

  svg {
    transform: scale(2.5);
  }
  .profile-icon {
    margin-top: -10px;
    width: 40px;
    max-height: 44px;
  }
`;

const UList = styled.ul`
  position: relative;
  display: inline-block;
  float: right;
  padding: 0px;

  .dropdown li {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: ${colors.white};
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 44px;
    left: auto;
    right: 0;
    margin-right: 0px;
  }

  .dropdown-content .list-item {
    width: 100%;
    display: block;
    cursor: pointer;
    border-bottom: 1px solid ${colors.lightversion};
    padding: 12px 16px;
  }

  .dropdown-content a {
    color: ${colors.primary.black90};
    text-decoration: none;
    display: block;
  }

  .dropdown-content .list-item:hover {
    background-color: ${colors.lightversion};
  }
`;

const RightNav = styled.div`
  &:hover .dropdown-content {
    display: block;
    left: auto;
    right: -36px;
    margin-right: 0px;
  }
`;

export const HeaderListItems = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [sidebarToggle, setSidebarToggle] = useState(true);

  const { userLogoutPorcess, userInfo, logoutSuccess, userProfileFindData, sidebarOpenStatus } = useSelector(
    ({ sign, userProfile, common }) => ({
      ...sign,
      ...userProfile,
      ...common
    }),
  );

  useEffect(() => {
    dispatch(userProfileFind());
  }, []);

  const userProfile = userProfileFindData?.data;

  useEffect(() => {
    if (!userLogoutPorcess && logoutSuccess?.data) {
      // window.location.href = '/login';
    }
  }, [userLogoutPorcess, logoutSuccess]);

  const menuClick = (item) => {
    if (item && item.isClick && item.key && item.key === 'logout') {
      dispatch(logout());
    }
  };

  // toggle click
  const toggleSidebar = () => {   
    if (sidebarOpenStatus?.status) {
      dispatch(openSidebar(false));
    }
    else {
      dispatch(openSidebar(true));
    }
  };

  return (
    <>
      <div className="left-nav">
        {/* sidebar collapsible toggler */}
        <div className="navbar-toggler">
          <Link to={'#'} className="navbar-toggler-link" onClick={() => toggleSidebar()}>
            <Menu />
          </Link>

          <div className="navbar-brand">
            <img src={NavBrand.logo.dark} />
          </div>
        </div>
      </div>

      <RightNav className="right-nav">
        <UList className="dropdown">
          <DropdownButton>
            <img
              src={
                userProfile?.profile_image?.length > 2
                  ? userProfile?.profile_image
                  : defaultProfileImage
              }
              className="profile-icon"
            />
          </DropdownButton>
          <div className="dropdown-content">
            {NavItems?.profile?.subMenu?.map((item, index) => (
              <li className="list-item" key={index}>
                <Link to={item?.link} onClick={() => menuClick(item)}>
                  <NavIconWrapper className="nav-icon-wrapper">{<item.icon />}</NavIconWrapper>
                  {item?.text}
                </Link>
              </li>
            ))}
          </div>
        </UList>
        <span className="logged-user-name">
          {userProfileFindData?.profile_image}
          {userProfileFindData && userProfile?.first_name + ' ' + userProfile?.last_name}
        </span>
      </RightNav>
    </>
  );
};

HeaderListItems.defaultProps = {};
