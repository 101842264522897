import React, { useState, useEffect } from 'react';
import { Tabs } from '../../data/LeavesData';
import { FormInputsData } from '../../data/EditLeaveData';
import { TabCustom, FormCustom } from '.';

export const AddViewEditLeave = ({ isEdit, hiddenKey, readOnly, url }) => {
  const [formData, setFormData] = useState({});

  const tabs = {
    ...Tabs,
    // list: commonFn.updateLink(Tabs.list, match),
  };

  useEffect(() => {
    setFormData({
      list: FormInputsData,
      isEdit,
    });
  }, []);

  return (
    <>
      <TabCustom {...tabs} />
      <FormCustom {...formData} />
    </>
  );
};
