import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { HrmsEmployeeMain } from './employee';
import { HrmsHolidaysMain } from './holidays';
import { ClientsMain } from './clients';
import { NotFound } from '../common';

export const HrmsLanding = () => {
  return (
    <>
      <Switch>
        <Route path={`/hrms/clients`} component={ClientsMain} />
        <Route path={`/hrms/employees`} component={HrmsEmployeeMain} />
        <Route path={`/hrms/holidays`} component={HrmsHolidaysMain} />
        <Route path={`**`} component={NotFound} />
      </Switch>
    </>
  );
};
