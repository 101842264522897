import React from 'react';
import { AttendanceHead, ResponseData } from '../../../data/AttendanceRegisterData';
import { Tabs } from '../../../data/LeavesData';
import { commonFn } from '../../../util/commonFn';
import { TabCustom, TableCustom } from '../../common';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const AttendanceRegister = () => {
  const history = useHistory();

  const tabs = {
    ...Tabs,
    // list: commonFn.updateLink(Tabs.list, match),
  };

  // Table data
  const tableData = {
    head: AttendanceHead,
    data: ResponseData,
  };

  return (
    <>
      <TabCustom {...tabs} />
      <Box mb={4}>
        <TableCustom {...tableData} />
      </Box>
    </>
  );
};
