import React, { useEffect, useState } from 'react';
import { PermissionInput } from '../../data/CreateUserData';
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  Box,
  TextField,
  InputLabel,
  MenuItem,
  FormGroup,
  Avatar,
  IconButton,
  Switch,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../../theme';
import { Controller } from 'react-hook-form';

export const Permissions = ({ permissionsFindAllData, form, formData, isView = false }) => {
  const [permissionInput, setPermissionInput] = useState(PermissionInput);
  const {
    formState: { errors },
    control,
    watch,
  } = form;

  const YellowSwitch = withStyles({
    switchBase: {
      color: colors?.primary?.yellowp1,
      '&$checked': {
        color: colors?.primary?.yellowp1,
      },
      '&$checked + $track': {
        backgroundColor: colors?.primary?.yellowp1,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  let hiddenKeys = [];
  if (
    (watch()?.role_slug?.slug === 'user' && watch()?.type_slug?.slug === 'client') ||
    (isView && formData?.value?.role_slug === 'user' && formData?.value?.type_slug === 'client')
  ) {
    hiddenKeys = [
      'create-user',
      'edit-user',
      'delete-user',
      'create-client',
      'list-clients',
      'edit-client',
      'delete-client',
    ];
  }
  if (
    (watch()?.type_slug?.slug === 'super-admin' && watch()?.role_slug?.slug === 'user') ||
    (isView &&
      formData?.value?.type_slug === 'super-admin' &&
      formData?.value?.role_slug === 'user')
  ) {
    hiddenKeys = ['create-user', 'edit-user', 'delete-user'];
  }
  if (
    ((watch()?.type_slug?.slug === 'super-admin' || watch()?.type_slug?.slug === 'client') &&
      watch()?.role_slug?.slug === 'admin') ||
    (((isView && formData?.value?.type_slug === 'super-admin') ||
      formData?.value?.type_slug === 'client') &&
      formData.value?.role_slug === 'admin')
  ) {
    hiddenKeys = ['show-client-ctc'];
  }
  if (watch()?.role_slug?.slug === 'admin') {
    hiddenKeys = [];
  }

  return (
    <>
      <Grid container spacing={1}>
        {permissionsFindAllData?.data?.list?.map((itemVal, index) => {
          if (hiddenKeys.indexOf(itemVal.slug) !== -1) {
            return null;
          }
          return (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={index}>
              <Box className={permissionInput?.formElementClass}>
                <FormControl className={permissionInput?.formControlClass}>
                  <Controller
                    render={({ field }) => {
                      return (
                        <FormControlLabel
                          control={
                            <YellowSwitch
                              color="secondary"
                              {...field}
                              checked={
                                isView
                                  ? formData?.value?.permission?.[itemVal.slug]
                                    ? true
                                    : false
                                  : field.value
                                  ? true
                                  : false
                              }
                              disabled={isView ? true : false}
                            />
                          }
                          label={itemVal?.permission}
                          labelPlacement={permissionInput.labelPlacement}
                        />
                      );
                    }}
                    control={control}
                    name={`permission.${itemVal.slug}`}
                    rules={{}}
                  />
                </FormControl>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
