import React, { useState } from 'react';
import { PrimaryButton } from '.';
import './DialogBox.scss';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { commonFn } from '../../util/commonFn';
import { TextEditor } from '.';

import {
  Grid,
  FormControl,
  Box,
  TextField,
  CircularProgress,
  TextareaAutosize,
} from '@material-ui/core';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const DialogBox = ({
  isOpened,
  closeDialog = () => {},
  list = [],
  values = {},
  options = {},
  formSubmit = () => {},
  changeEvent = () => {},
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm();

  const handleClose = () => {
    closeDialog();
  };

  const onChangeValue = (data) => {
    changeEvent(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(formSubmit)}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={isOpened}
          className={'modal'}
        >
          <div className="dialog-header" onClose={handleClose}>
            <h2>{list?.groupLabel} </h2>

            <IconButton aria-label="close" onClick={handleClose} className={'modal-close'}>
              <CloseIcon />
            </IconButton>
          </div>

          <DialogContent dividers className={'dialog-content'}>
            <Grid container>
              {list.inputs &&
                list.inputs.map((input, inputIndex) => (
                  <Grid
                    item
                    xs={input?.xs || 12}
                    sm={input?.sm || 12}
                    md={input?.md || 6}
                    lg={input?.lg || 4}
                    xl={input?.xl || 4}
                    key={inputIndex}
                  >
                    {input?.type === 'text' && (
                      <Box className={input?.formElementClass}>
                        <label className={input?.labelClass}> {input?.label} </label>

                        <Box pb={1}>
                          <FormControl className={input?.formControlClass}>
                            <TextField
                              className={input?.fieldClass}
                              placeholder={input?.placeholder}
                              variant={input?.variant}
                              margin={input?.margin}
                              name={input?.name}
                              type={input?.type}
                              multiline={input?.multiline}
                              rows={input?.rows}
                              readOnly={input?.readOnly}
                              maxLength={input?.maxLength}
                              disabled={input?.disabled}
                              defaultValue={
                                (values?.[input?.name] && values?.[input?.name]) || input?.value
                              }
                              {...register(input?.name)}
                              onChange={(e) => onChangeValue(e)}
                            />
                          </FormControl>
                        </Box>
                      </Box>
                    )}

                    {input?.type === 'multiSelectCreate' && (
                      <Box className={input?.formElementClass}>
                        <label className={input?.labelClass}> {input?.label} </label>

                        <Box pb={1}>
                          <FormControl className={input?.formControlClass}>
                            <CreatableSelect
                              defaultValue={values?.[input?.valueId]}
                              isMulti={input?.type === 'multiSelectCreate' ? true : false}
                              isClearable={true}
                              name={input?.name}
                              className={input?.fieldClass}
                              placeholder={input?.placeholder}
                              options={options?.[input?.optionsKey] || []}
                              onChange={(value) => {
                                onChangeValue({
                                  value: value,
                                  field: input,
                                  watch: watch(),
                                });
                              }}
                            />
                          </FormControl>
                        </Box>
                      </Box>
                    )}

                    {/* text area  */}
                    {input?.type === 'resizableTextArea' && (
                      <Box className={input?.formElementClass}>
                        <label className={input?.labelClass}> {input?.label} </label>

                        <Box pb={1}>
                          <FormControl className={input?.formControlClass}>
                            <TextareaAutosize
                              placeholder={input?.placeholder}
                              className={input?.textAreaClass}
                              variant={input?.variant}
                              margin={input?.margin}
                              name={input?.name}
                              type={input?.type}
                              rows={input?.rows}
                              onChange={(value) => {
                                onChangeValue({
                                  value: value,
                                  field: input,
                                  watch: watch(),
                                });
                              }}
                            />
                          </FormControl>
                        </Box>
                      </Box>
                    )}
                    
                    {/* <TextEditor /> */}
                  </Grid>
                ))}
            </Grid>
          </DialogContent>

          <DialogActions>
            <span onClick={formSubmit}>
              <PrimaryButton text={'Send'} />
            </span>

            <span className={'view-button ml-10'} onClick={handleClose}>
              <PrimaryButton class={'view-close'} text={'Cancel'} />
            </span>
          </DialogActions>
        </BootstrapDialog>
      </form>
    </div>
  );
};
