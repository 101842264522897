import React from 'react';
import { Tabs, LeavesHead, ResponseData } from '../../../data/LeavesData';
import { TabCustom, TableCustom } from '../../common';
import { commonFn } from '../../../util/commonFn';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const Leaves = () => {
  const history = useHistory();

  const tabs = {
    ...Tabs,
    // list: commonFn.updateLink(Tabs.list, match),
  };

  // Actions click function
  const clickOnCell = (data, event) => {
    event.stopPropagation();
    const leaveId = data.row[0].value;
    if (leaveId && data?.action?.type === 'edit') {
      history.push(`/company/leaves/edit/${leaveId}`);
    }
  };

  // Table data
  const tableData = {
    head: LeavesHead,
    data: ResponseData,
    clickOnCell: clickOnCell,
  };

  return (
    <>
      <TabCustom {...tabs} />
      <Box mb={4}>
        <TableCustom {...tableData} />
      </Box>
    </>
  );
};
