import { Validation } from './CommonData';

export const FormInputsData = [
  {
    groupLabel: 'Personal Details',
    groupLabelClass: 'fieldset-label',
    key: 'personal',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'First Name',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'first_name',
        name: 'first_name',
        placeholder: 'First Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 3,
        maxLength: 20,
        errors: [
          { type: 'required', message: 'Please enter the first name' },
          { type: 'minLength', message: 'Please enter atleast three characters' },
        ],
      },
      {
        label: 'Last Name',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'last_name',
        name: 'last_name',
        placeholder: 'Last Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 1,
        maxLength: 50,
        errors: [{ type: 'required', message: 'Please enter the last name' }],
      },
      {
        label: 'Email',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'email',
        name: 'email',
        hiddenKey: 'email',
        placeholder: 'Email',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        // isRequired: true,
        maxLength: 100,
        pattern: Validation.email,
        errors: [
          { type: 'required', message: 'Please enter the email' },
          { type: 'pattern', message: 'Email must be a valid email address' },
        ],
      },
      {
        label: 'LinkedIn',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'linkedin',
        name: 'linkedin',
        hiddenKey: 'linkedin',
        placeholder: 'LinkedIn',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Phone no.',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'phone',
        name: 'phone',
        hiddenKey: 'phone',
        placeholder: 'Phone no.',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        // isRequired: true,
        pattern: Validation.numberAndPlus,
        minLength: 10,
        maxLength: 15,
        errors: [
          { type: 'required', message: 'Please enter the mobile number' },
          {
            type: 'pattern',
            message: 'Mobile number must be a valid number',
          },
          {
            type: 'minLength',
            message: 'Mobile number should be of minimum 10 digits',
          },
          {
            type: 'maxLength',
            message: 'Mobile number should be maximum 15 digits',
          },
        ],
      },
      {
        label: 'Applied On',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'applied_date',
        name: 'applied_date',
        placeholder: 'Applied Date',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Country',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'country_id',
        name: 'country_id',
        placeholder: 'Select Country',
        hide: false,
        optionsKey: 'country',
        valueId: 'country_id',
        displayKey: 'country',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle z-index-4', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        // isRequired: true,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Select or create the country' }],
      },
      {
        label: 'State',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'state_id',
        name: 'state_id',
        placeholder: 'Select State',
        hide: false,
        optionsKey: 'state',
        valueId: 'state_id',
        displayKey: 'state',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle z-index-4', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
      {
        label: 'City',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'city_id',
        name: 'city_id',
        placeholder: 'Select City',
        hide: false,
        optionsKey: 'city',
        valueId: 'city_id',
        displayKey: 'city',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle z-index-4', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
      {
        label: 'Skills',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 9,
        xl: 9,
        key: 'skill_id',
        name: 'skill_id',
        placeholder: 'Select Skills',
        hide: false,
        formControlClass: 'input-form-control',
        optionsKey: 'skills',
        valueId: 'skill_id',
        displayKey: 'skill_name',
        type: 'multiSelectCreate', // multiSelectCreate
        fieldClass: 'multiSelectStyle z-index-3', //  multiSelectStyle z-index-3
        variant: 'outlined',
        margin: 'dense',
        size: 'small',
        formElementClass: 'form-input-box',
        isRequired: true,
        // minLength: 3,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Please select the skill(s)' }],
      },
    ],
  },
  {
    groupLabel: 'Professional Details',
    groupLabelClass: 'fieldset-label',
    key: 'professional',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Source',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'source_id',
        name: 'source_id',
        hiddenKey: 'phone',
        placeholder: 'Select Source',
        hide: false,
        optionsKey: 'source',
        valueId: 'source_id',
        displayKey: 'source',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate', // select
        fieldClass: 'textBoxStyle', //  multiSelectStyle z-index-2
        variant: 'outlined',
        margin: 'dense',
        size: 'small',
        // isRequired: true,
        errors: [{ type: 'required', message: 'Please select the source' }],
        formElementClass: 'form-input-box',
      },
      {
        label: 'Current Position',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'current_position',
        name: 'current_position',
        placeholder: 'Current Position',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Current Company',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'current_company',
        name: 'current_company',
        placeholder: 'Current Company',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Notice Period',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'notice_period',
        name: 'notice_period',
        placeholder: 'Notice Period',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        // isRequired: true,
        minLength: 1,
        maxLength: 10,
        errors: [
          { type: 'required', message: 'Enter the notice period (eg. 30 days)' },
          { type: 'pattern', message: 'Please enter a valid days (eg. 30 days)' },
        ],
      },
      {
        label: 'Date of Joining',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'joining_date',
        name: 'joining_date',
        placeholder: 'Date of Joining',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Current CTC',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'current_ctc',
        name: 'current_ctc',
        placeholder: 'Current CTC',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        // isRequired: true,
        hiddenKey: 'current_ctc',
        minLength: 4,
        maxLength: 20,
        pattern: Validation.numberOnly,
        errors: [
          { type: 'required', message: 'Please enter the current ctc (eg. 500000)' },
          { type: 'pattern', message: 'CTC must be a valid number format (eg. 500000)' },
          { type: 'minLength', message: 'CTC must be at least 4 digit number (eg. 5000)' },
        ],
      },
      {
        label: 'Expected CTC',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'expected_ctc',
        name: 'expected_ctc',
        placeholder: 'Expected CTC',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        // isRequired: true,
        minLength: 4,
        maxLength: 20,
        hiddenKey: 'expected_ctc',
        pattern: Validation.numberOnly,
        errors: [
          { type: 'required', message: 'Please enter the expected ctc' },
          { type: 'pattern', message: 'Expected CTC must be a valid number format (eg. 500000)' },
          { type: 'minLength', message: 'Expected CTC must be at least 4 digit number (eg. 5000)' },
        ],
      },
      {
        label: 'Client CTC',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'client_ctc',
        name: 'client_ctc',
        hiddenKey: 'client_ctc',
        placeholder: 'Client CTC',
        aliasLabel: 'client_ctc_label',
        aliasPlaceholder: 'client_ctc_placeholder',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        // isRequired: true,
        minLength: 4,
        maxLength: 20,
        hiddenKey: 'client_ctc',
        pattern: Validation.numberOnly,
        errors: [
          { type: 'required', message: 'Please enter the CTC' },
          { type: 'pattern', message: 'CTC must be a valid number format (eg. 500000)' },
          { type: 'minLength', message: 'CTC must be at least 4 digit number (eg. 5000)' },
        ],
      },
      {
        label: 'Experience',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'total_experience',
        name: 'total_experience',
        placeholder: 'Total Experience',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        // isRequired: true,
        minLength: 1,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Please enter the total experience' }],
      },
      {
        label: 'Internal HR Comments',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'internal_hr_comments',
        name: 'internal_hr_comments',
        hiddenKey: 'internal_hr_comments',
        placeholder: 'Internal HR Comments',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        multiline: true,
        rows: 6,
        // isRequired: true,
        minLength: 1,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Please enter the comment' }],
      },
      {
        label: '',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 4,
        xl: 4,
        key: 'cv',
        name: 'cv',
        placeholder: 'Open CV',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'link',
        fieldClass: '',
        variant: '',
        margin: '',
        formElementClass: 'form-input-box',
        url: '',
        target: '_blank',
      },

      {
        label: '',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'cv',
        name: 'cv',
        placeholder: '',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'dropzone',
        fieldClass: '',
        variant: '',
        margin: '',
        formElementClass: 'form-input-box',
      },
    ],
  },
  {
    groupLabel: 'Current Interview Status',
    groupLabelClass: 'fieldset-label',
    key: 'interview',
    groupClass: 'form-fieldset',
    inputs: [
      // {
      //   label: 'Last Interview Status',
      //   xs: 12,
      //   sm: 12,
      //   md: 6,
      //   lg: 4,
      //   xl: 4,
      //   key: 'last_interview_status',
      //   name: 'last_interview_status',
      //   placeholder: '',
      //   hide: false,
      //   formControlClass: 'input-form-control',
      //   type: 'text',
      //   fieldClass: 'textBoxStyle',
      //   variant: 'outlined',
      //   margin: 'dense',
      //   formElementClass: 'form-input-box',
      //   readOnly: true,
      //   defaultValue: 'Last Interview Status',
      // },
      {
        label: 'Client',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'client_id',
        name: 'client_id',
        placeholder: 'Select Client',
        optionsKey: 'clients',
        valueId: 'client_id',
        displayKey: 'company_name',
        isChangeEvent: true,
        hide: false,
        formControlClass: 'input-form-control',
        type: 'select', // multiselect
        fieldClass: 'textBoxStyle', //  multiSelectStyle z-index-2
        variant: 'outlined',
        margin: 'dense',
        size: 'small',
        formElementClass: 'form-input-box',
        isRequired: true,
        errors: [{ type: 'required', message: 'Please choose client' }],
      },
      {
        label: 'Position Applying For',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'applying_position',
        name: 'applying_position',
        valueId: 'applying_position',
        placeholder: 'Select Position',
        displayKey: 'position',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'multiselect',
        optionsKey: 'positions',
        isChangeEvent: true,
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        errors: [{ type: 'required', message: 'Please choose position applying for' }],
      },
      {
        type: 'renderer',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'InterviewStatus',
      },

      {
        type: 'renderer',
        hiddenKey: 'upload_cv',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'UploadCV',
      },
      {
        type: 'renderer',
        hiddenKey: '', // client_cv
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'UploadClientCV',
      },
    ],
  },
];

export const InterviewInput = [
  {
    label: 'Add Interview Status',
    displayLabel: 'Interview Status',
    containerClass: 'interview_status_row',
    xs: 12,
    sm: 12,
    md: 12,
    lg: 3,
    xl: 3,
    statusName: 'status.1.value',
    commentName: 'comment.1.value',
    dateName: 'date.1.value',
    formControlClass: 'input-form-control',
    fieldClass: 'text-box-style',
    textAreaClass: 'resizableTextArea',
    statusPlaceholder: 'Status',
    commentPlaceholder: 'Comment',
    datePlaceholder: 'Date',
    variant: 'outlined',
    margin: 'dense',
    formElementClass: 'form-input-box',
    keyValue: 1,
  },
];

export const FileUpload = {
  label: 'Resume',
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
  uploadType: 'doc',
  key: 'cv_name',
  placeholder: 'Upload Resume',
  formControlClass: 'input-form-control file-upload-control',
  type: 'file',
  imageClass: 'form-control-image',
  displayImageClass: 'display-avatar-logo',
  name: 'job_description',
  fieldClass: 'input-file-button',
  id: 'company-avatar-upload',
  variant: 'outlined',
  margin: 'dense',
  formElementClass: 'form-input-box file-upload-element jd-upload',
  uploadTextClass: 'file-label',
};

export const ClientCVUpload = {
  label: 'Client CV',
  clientCvLabel: 'client_cv_label',
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
  uploadType: 'doc',
  key: 'client_cv_name',
  placeholder: 'Upload Client CV',
  clientCvPlaceholder: 'client_cv_placeholder',
  formControlClass: 'input-form-control file-upload-control',
  type: 'file',
  imageClass: 'form-control-image',
  displayImageClass: 'display-avatar-logo',
  name: 'client_cv',
  hiddenKey: 'client_cv',
  fieldClass: 'input-file-button',
  id: 'client-cv-upload',
  variant: 'outlined',
  margin: 'dense',
  formElementClass: 'form-input-box file-upload-element jd-upload',
  uploadTextClass: 'file-label',
};
