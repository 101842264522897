import { PhotoCamera } from '@material-ui/icons';
import { Validation } from './CommonData';

export const FormInputsData = [
  {
    groupLabel: 'Invoice Detail',
    groupLabelClass: 'fieldset-label',
    key: 'invoice_detail',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Invoice Number',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'invoice_number',
        name: 'invoice_number',
        placeholder: 'Invoice Number',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 1,
        maxLength: 40,
        errors: [{ type: 'required', message: 'Please enter invoice number' }],
      },
      {
        label: 'Invoiced Date',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'invoiced_date',
        name: 'invoiced_date',
        placeholder: 'Invoiced Date',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 1,
        maxLength: 40,
        errors: [{ type: 'required', message: 'Please select invoice date' }],
      },
      {
        label: 'Due Date',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'due_date',
        name: 'due_date',
        placeholder: 'Due Date',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Total Amount',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'total_amount',
        name: 'total_amount',
        placeholder: 'Total Amount',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Invoice Status',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'invoice_status_id',
        name: 'invoice_status_id',
        placeholder: 'Select Status',
        hide: false,
        optionsKey: 'invoiceStatus',
        valueId: 'invoice_status_id',
        displayKey: 'invoice_status',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
    ],
  },
  {
    groupLabel: 'Flipkoins Account Detail',
    groupLabelClass: 'fieldset-label',
    key: 'flipkoins_account_detail',
    name: 'flipkoins_account_detail',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Account Number',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'vendor_bank_account_number',
        name: 'vendor_bank_account_number',
        placeholder: 'Account Number',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'IFSC Code',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'ifsc_code',
        name: 'ifsc_code',
        placeholder: 'IFSC Code',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Swift Code',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'swift_code',
        name: 'swift_code',
        placeholder: 'Swift Code',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Branch',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'branch',
        name: 'branch',
        placeholder: 'Branch',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Intermediary Bank Details',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'more_bank_details',
        name: 'more_bank_details',
        placeholder: 'Intermediary Bank Details',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        variant: 'outlined',
        margin: 'dense',
        rows: 6,
        formElementClass: 'form-input-box',
      },
    ],
  },
  {
    groupLabel: 'Bill To Detail',
    groupLabelClass: 'fieldset-label',
    key: 'bill_to_detail',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Company',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'company_id',
        name: 'company_id',
        placeholder: 'Select Company',
        hide: false,
        optionsKey: 'clients',
        valueId: 'company_id',
        displayKey: 'company_name',
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        errors: [{ type: 'required', message: 'Select client' }],
      },
      {
        label: 'Billing Email',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'billing_email',
        name: 'billing_email',
        placeholder: 'Select Billing Email',
        hide: false,
        optionsKey: 'billing_email',
        valueId: 'billing_email',
        displayKey: 'position_type',
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle multiSelectStyle z-index-2',
        variant: 'outlined',
        options: [],
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        errors: [{ type: 'required', message: 'Select billing email' }],
      },

      {
        label: 'GSTIN',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'gstin',
        name: 'gstin',
        placeholder: 'GSTIN',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        maxLength: 100,
      },
      {
        label: 'Address',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'billing_address',
        name: 'billing_address',
        placeholder: 'Address',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        rows: 6,
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'City',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 4,
        xl: 4,
        key: 'billing_city_id',
        name: 'billing_city_id',
        placeholder: 'Select City',
        hide: false,
        optionsKey: 'billing_city',
        valueId: 'billing_city_id',
        displayKey: 'billing_city',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
      {
        label: 'Zip Code',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'billing_zipcode',
        name: 'billing_zipcode',
        placeholder: 'Zip Code',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'State',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 4,
        xl: 4,
        key: 'billing_state_id',
        name: 'billing_state_id',
        placeholder: 'Select State',
        hide: false,
        optionsKey: 'billing_state',
        valueId: 'billing_state_id',
        displayKey: 'billing_state',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
      {
        label: 'Country',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 4,
        xl: 4,
        key: 'billing_country_id',
        name: 'billing_country_id',
        placeholder: 'Select Country',
        hide: false,
        optionsKey: 'billing_country',
        valueId: 'billing_country_id',
        displayKey: 'billing_country',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
    ],
  },
  {
    groupLabel: 'Invoice Attachment',
    groupLabelClass: 'fieldset-label',
    key: 'invoice_attachment',
    groupClass: 'form-fieldset',
    inputs: [
      {
        type: 'renderer',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'UploadInvoice',
      },
    ],
  },
];

// upload resume
export const FileUpload = {
  label: 'Invoice Attachment',
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
  xl: 6,
  uploadType: 'doc',
  key: 'invoice_attachment_name',
  placeholder: '',
  formControlClass: 'input-form-control file-upload-control',
  type: 'file',
  imageClass: 'form-control-image',
  displayImageClass: 'display-avatar-logo',
  placeholder: 'Upload Invoice Attachment',
  name: 'invoice_attachment',
  fieldClass: 'input-file-button',
  id: 'invoice-upload',
  variant: 'outlined',
  margin: 'dense',
  formElementClass: 'form-input-box file-upload-element jd-upload',
  uploadTextClass: 'file-label',
};

// page header, title, breadcrumb and button
export const PageHeader = {
  className: 'page-header',
  page_title: 'Accounts',
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Company', link: '/company/profile', className: 'active' },
    { text: 'Invoices', link: '', className: 'active' },
  ],
};

// Tabs data
export const Tabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Invoices',
      key: 'view',
      link: '/company/invoices',
      path: '/company/invoices',
      className: 'tab-link',
      hiddenKey: 'view-invoices',
    },
  ],
};
