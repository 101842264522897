import React from 'react';
import { ProfileRouter } from '.';
import { DashboardHeader } from '../common';
import {
  PageHeader,
} from '../../data/ProfileData';
import { Container } from '@material-ui/core';

export const ProfileMain = (props) => {
  const headerData = { header: PageHeader };
  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <ProfileRouter />
    </Container>
  );
};
