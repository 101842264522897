import { Edit, Info, Email, Print } from '@material-ui/icons';

// Applicants table data
export const ResponseData = [
  {
    id: 1,
    avatar: 'JD',
    name: 'John Doe',
    from: 'Jan 01, 2021',
    to: 'Jan 05, 2021',
    total_days: 5,
    status: 'Approved',
    approver: 'HR',
  },
  {
    id: 2,
    avatar: 'RN',
    name: 'Raghu Nathan',
    from: 'Feb 10, 2021',
    to: 'Feb 12, 2021',
    total_days: 5,
    status: 'Pending',
    approver: 'HR',
  },
  {
    id: 3,
    avatar: 'JD',
    name: 'John Doe',
    from: 'Jan 01, 2021',
    to: 'Jan 05, 2021',
    total_days: 5,
    status: 'Approved',
    approver: 'HR',
  },
];

// holidays table header
export const LeavesHead = [
  { label: 'id', key: 'id', hide: true, type: 'text', headClass: ' ', bodyClass: '' },
  {
    label: 'Avatar',
    key: 'avatar',
    hide: false,
    type: 'file',
    headClass: 'w-80',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo',
  },
  {
    label: 'Name',
    key: 'name',
    hide: false,
    headClass: 'w-200',
    type: 'text',
    link: '',
  },
  {
    label: 'From',
    key: 'from',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'To',
    key: 'to',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'No. of Days',
    key: 'total_days',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'Status',
    key: 'status',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'Approver',
    key: 'approver',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'Action',
    key: 'actions',
    hide: false,
    type: 'actions',
    headClass: 'w-150 action-head',
    actions: [
      {
        bodyClass: 'icon-success',
        type: 'edit',
        icon: Edit,
        text: 'Edit',
        click: '',
        url: '',
        actionClass: 'icon-wrapper',
      },
    ],
  },
];

// page header, title, breadcrumb and button
export const PageHeader = {
  className: 'page-header',
  page_title: 'Leaves',
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Company', link: '/company/profile', className: 'active' },
    { text: 'Leaves', link: '', className: 'active' },
  ],
};

// Tabs data
export const Tabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Employee Leaves',
      key: 'list-employee-leaves',
      link: '/company/leaves',
      path: '/company/leaves',
      className: 'tab-link',
      hiddenKey: 'employee-leaves',
    },
    // {
    //   label: 'Edit Employee Leave',
    //   key: 'edit',
    //   link: '/company/leaves/edit/:id',
    //   path: '/company/leaves/edit/:id',
    //   className: 'tab-link',
    //   hiddenKey: 'edit-employee-leave',
    // },
    {
      label: 'Attendance Register',
      key: 'view',
      link: '/company/attendance',
      path: '/company/attendance',
      className: 'tab-link',
      hiddenKey: 'attendance-register',
    },
  ],
};
