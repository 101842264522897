import React, { useEffect, useState } from 'react';
import { FormInputsData, DocumentUploadInputs } from '../../../data/CreateClientData';
import { FormCustom, TabCustom } from '../../common';
import { Tabs } from '../../../data/ClientsData';
import { commonFn } from '../../../util/commonFn';
import { createClient, clientFind, updateClient } from '../../../redux/clients/action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openSnackBarLayout } from '../../../redux/common/action';
import { timezonesFindAll } from '../../../redux/master/action';
import axios from 'axios';
import { apiList } from '../../../util/apiList';
import {
  citiesFindAll,
  statesFindAll,
  countriesFindAll,
  createCountry,
  createState,
  createCity,
} from '../../../redux/cities/action';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import { CircularProgress, Grid, Link, Box, FormControl } from '@material-ui/core';
import CreatableSelect from 'react-select/creatable';
import { createDocumentType, documentTypesFindAll } from '../../../redux/document_type/action';
import { Controller, useFieldArray } from 'react-hook-form';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export const CreateClient = ({ match, isEdit, isView, options = {}, values = {} }) => {
  const {
    createClientProcess,
    createClientError,
    createClientData,
    clientFindError,
    clientFindData,
    clientFindProcess,
    updateClientProcess,
    updateClientError,
    updateClientData,
    timezonesFindAllProcess,
    timezonesFindAllError,
    timezonesFindAllData,
    cityFindAllProcess,
    cityFindAllError,
    cityFindAllData,
    stateFindAllProcess,
    stateFindAllError,
    stateFindAllData,
    countryFindAllProcess,
    countryFindAllError,
    countryFindAllData,
    countryCreateProcess,
    countryCreateError,
    countryCreateData,
    stateCreateProcess,
    stateCreateError,
    stateCreateData,
    cityCreateProcess,
    cityCreateError,
    cityCreateData,
    documentTypesFindAllProcess,
    documentTypesFindAllError,
    documentTypesFindAllData,
    documentTypesCreateProcess,
    documentTypesCreateError,
    documentTypesCreateData,
  } = useSelector(({ clients, timezones, citiesNew, documentTypes }) => ({
    ...clients,
    ...timezones,
    ...citiesNew,
    ...documentTypes,
  }));

  const dispatch = useDispatch();
  const { push } = useHistory();
  const [formData, setFormData] = useState({});

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isRouteChange, setIsRouteChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDocumentUploading, setIsDocumentUploading] = useState({});
  const clientDocumentGroupKey = 'client';

  useEffect(() => {
    setIsRouteChange(true);
    dispatch(timezonesFindAll());
    match?.params?.id && dispatch(clientFind(match?.params?.id));
    dispatch(citiesFindAll());
    dispatch(statesFindAll());
    dispatch(countriesFindAll());
    dispatch(documentTypesFindAll(clientDocumentGroupKey));

    setFormData({
      list: FormInputsData,
      isEdit,
      isView,
      values: {},
      error: '',
      inProgress: isEdit ? true : false,
      options: {},
      isUploadDocument: false,
    });
    setTimeout(() => setIsRouteChange(false), 50);
    setIsFormSubmitted(false);
  }, [match.params.id]);

  // tabs data
  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  useEffect(() => {
    if (isEdit) {
      if (!clientFindProcess && !clientFindError && clientFindData?.data) {
        setFormData((data) => ({ ...data, values: clientFindData?.data, inProgress: false }));
      } else if (!clientFindProcess && clientFindError && !clientFindData?.data) {
        const error = clientFindData?.message || clientFindError?.message;

        setFormData((data) => ({ ...data, error, inProgress: false }));
      }
    }
  }, [clientFindProcess, clientFindError, clientFindData]);

  // document types find all
  useEffect(() => {
    if (!documentTypesCreateProcess && !documentTypesCreateError && documentTypesCreateData?.data) {
      dispatch(documentTypesFindAll(clientDocumentGroupKey));
    }
  }, [documentTypesCreateProcess, documentTypesCreateError, documentTypesCreateData]);

  // create document type
  const createNewDocumentType = (docType) => {
    const docTypeData = {
      document_type: docType,
      group_key: clientDocumentGroupKey,
    };
    dispatch(createDocumentType(docTypeData));
  };

  // set document types in state
  useEffect(() => {
    if (!documentTypesFindAllProcess && !documentTypesFindAllError && documentTypesFindAllData) {
      const document_types =
        documentTypesFindAllData?.data?.list?.map((docType) => {
          return { label: docType?.type, value: docType?.id };
        }) || [];

      setFormData((data) => ({
        ...data,
        options: { ...data.options, document_types: document_types },
      }));
    }
  }, [documentTypesFindAllProcess, documentTypesFindAllError, documentTypesFindAllData]);

  // Country
  useEffect(() => {
    if (!countryFindAllProcess && !countryFindAllError && countryFindAllData?.data) {
      const country =
        (countryFindAllData &&
          countryFindAllData?.data?.list?.map((country) => {
            return { label: country?.country, value: country?.id };
          })) ||
        [];

      const getId = clientFindData?.data?.country_id;
      const getBillingId = clientFindData?.data?.billing_country_id;

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && clientFindData?.data
            ? {
                ...data.values,
                country_id:
                  typeof data.values?.country_id === 'object'
                    ? data.values?.country_id
                    : commonFn.getMultiValueFromId(getId, country)[0],
                billing_country_id:
                  typeof data.values?.billing_country_id === 'object'
                    ? data.values?.billing_country_id
                    : commonFn.getMultiValueFromId(getBillingId, country)[0],
              }
            : data.values,
        options: { ...data.options, country, billing_country: country },
      }));
    }
  }, [countryFindAllProcess, countryFindAllError, countryFindAllData, clientFindData]);

  // City
  useEffect(() => {
    if (!cityFindAllProcess && !cityFindAllError && cityFindAllData?.data) {
      const city =
        (cityFindAllData &&
          cityFindAllData?.data?.list?.map((city) => {
            return { label: city?.city, value: city?.id };
          })) ||
        [];

      const getBillingId = clientFindData?.data?.billing_city_id;
      const getId = clientFindData?.data?.city_id;

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && clientFindData?.data
            ? {
                ...data.values,
                city_id:
                  typeof data.values?.city_id === 'object'
                    ? data.values?.city_id
                    : commonFn.getMultiValueFromId(getId, city)[0],
                billing_city_id:
                  typeof data.values?.billing_city_id === 'object'
                    ? data.values?.billing_city_id
                    : commonFn.getMultiValueFromId(getBillingId, city)[0],
              }
            : data.values,
        options: { ...data.options, city, billing_city: city },
      }));
    }
  }, [cityFindAllProcess, cityFindAllError, cityFindAllData, clientFindData]);

  // State
  useEffect(() => {
    if (!stateFindAllProcess && !stateFindAllError && stateFindAllData?.data) {
      const state =
        (stateFindAllData &&
          stateFindAllData?.data?.list?.map((state) => {
            return { label: state?.state, value: state?.id };
          })) ||
        [];

      const getBillingId = clientFindData?.data?.billing_state_id;
      const getId = clientFindData?.data?.state_id;

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && clientFindData?.data
            ? {
                ...data.values,
                state_id:
                  typeof data.values?.state_id === 'object'
                    ? data.values?.state_id
                    : commonFn.getMultiValueFromId(getId, state)[0],
                billing_state_id:
                  typeof data.values?.billing_state_id === 'object'
                    ? data.values?.billing_state_id
                    : commonFn.getMultiValueFromId(getBillingId, state)[0],
              }
            : data.values,
        options: { ...data.options, state, billing_state: state },
      }));
    }
  }, [stateFindAllProcess, stateFindAllError, stateFindAllData, clientFindData]);

  // create country onchange event
  const createNewCountry = (country) => {
    const countryData = {
      country: country,
    };
    dispatch(createCountry(countryData));
  };

  // Refresh billing country
  useEffect(() => {
    if (!countryCreateProcess && !countryCreateError && countryCreateData?.data) {
      const billingCountryValue = formData.values?.billing_country_id;

      const billing_country = billingCountryValue &&
        billingCountryValue?.__isNew__ && {
          label: countryCreateData?.data?.country,
          value: countryCreateData?.data?.id,
        };

      if (billingCountryValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            billing_country_id: billing_country,
          },
        }));
      }
      const countryValue = formData.values?.country_id;
      const country = countryValue &&
        countryValue?.__isNew__ && {
          label: countryCreateData?.data?.country,
          value: countryCreateData?.data?.id,
        };

      if (countryValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            country_id: country,
          },
        }));
      }
      dispatch(countriesFindAll());
    }
  }, [countryCreateProcess, countryCreateError, countryCreateData]);

  // create state onchange event
  const createNewState = (state) => {
    const stateData = {
      state: state,
    };
    dispatch(createState(stateData));
  };

  // Refresh state after creating new
  useEffect(() => {
    if (!stateCreateProcess && !stateCreateError && stateCreateData?.data) {
      const stateValue = formData.values?.state_id;
      const state = stateValue &&
        stateValue?.__isNew__ && {
          label: stateCreateData?.data?.state,
          value: stateCreateData?.data?.id,
        };

      if (stateValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            state_id: state,
          },
        }));
      }

      const billingStateValue = formData.values?.billing_state_id;
      const billingState = billingStateValue &&
        billingStateValue?.__isNew__ && {
          label: stateCreateData?.data?.state,
          value: stateCreateData?.data?.id,
        };

      if (billingStateValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            billing_state_id: billingState,
          },
        }));
      }
      dispatch(statesFindAll());
    }
  }, [stateCreateProcess, stateCreateError, stateCreateData]);

  // create city onchange event
  const createNewCity = (city) => {
    const cityData = {
      city: city,
    };
    dispatch(createCity(cityData));
  };

  useEffect(() => {
    if (!cityCreateProcess && !cityCreateError && cityCreateData?.data) {
      const billingCityValue = formData.values?.billing_city_id;
      const billingCity = billingCityValue &&
        billingCityValue?.__isNew__ && {
          label: cityCreateData?.data?.city,
          value: cityCreateData?.data?.id,
        };

      if (billingCityValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            billing_city_id: billingCity,
          },
        }));
      }

      const cityValue = formData.values?.city_id;
      const city = cityValue &&
        cityValue?.__isNew__ && {
          label: cityCreateData?.data?.city,
          value: cityCreateData?.data?.id,
        };

      if (cityValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            city_id: city,
          },
        }));
      }
      dispatch(citiesFindAll());
    }
  }, [cityCreateProcess, cityCreateError, cityCreateData]);

  // set timezones
  useEffect(() => {
    if (!timezonesFindAllProcess && !timezonesFindAllError && timezonesFindAllData?.data) {
      const timezones =
        (timezonesFindAllData &&
          timezonesFindAllData?.data?.list?.map((timezone) => {
            return { label: timezone?.timezone_display_name, value: timezone?.id };
          })) ||
        [];

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && clientFindData?.data
            ? {
                ...data.values,
                timezone_id: commonFn.getMultiValueFromId(
                  clientFindData?.data?.timezone_id,
                  timezones,
                )[0],
              }
            : data.values,
        options: { ...data.options, timezones },
      }));
    }
  }, [timezonesFindAllProcess, timezonesFindAllError, timezonesFindAllData, clientFindData]);

  // create client success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!createClientProcess && !createClientError && createClientData) {
        dispatch(openSnackBarLayout(createClientData.message, 'success', 1000));
        setIsLoading(() => false);
        push(`/hrms/clients/view/${createClientData?.data?.id}`);
      } else if (createClientError) {
        setIsLoading(() => false);
        dispatch(openSnackBarLayout(createClientError?.message, 'error', 1000));
      }
    }
  }, [createClientProcess, createClientError, createClientData]);

  // update client success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!updateClientProcess && !updateClientError && updateClientData) {
        dispatch(openSnackBarLayout(updateClientData.message, 'success', 1000));
        setIsLoading(() => false);
        push(`/hrms/clients/view/${updateClientData?.data?.id}`);
      } else if (createClientError) {
        setIsLoading(() => false);
        dispatch(openSnackBarLayout(createClientError?.message, 'error', 1000));
      }
    }
  }, [updateClientProcess, updateClientError, updateClientData]);

  // form submit action
  const formSubmit = (data) => {
    const request = data;
    setIsFormSubmitted(true);
    setIsLoading(true);
    request.company_logo = formData?.values?.company_logo || '';
    request.timezone_id = data?.timezone_id?.value || data?.timezone_id || '';

    request.country_id = commonFn.getIdFromMultiValue(data?.country_id).value;
    request.city_id = commonFn.getIdFromMultiValue(data?.city_id).value;
    request.state_id = commonFn.getIdFromMultiValue(data?.state_id).value;

    request.billing_country_id = commonFn.getIdFromMultiValue(data?.billing_country_id).value;
    request.billing_city_id = commonFn.getIdFromMultiValue(data?.billing_city_id).value;
    request.billing_state_id = commonFn.getIdFromMultiValue(data?.billing_state_id).value;

    const documents = [];
    data.documentList?.forEach((obj) => {
      if (obj.document_type?.value && obj.document) {
        let typeId = obj.document_type?.value;

        if (obj.document_type.__isNew__ === true) {
          typeId = commonFn.getIdfromSingleValue(
            obj.document_type.value,
            formData.options.document_types,
          )?.value;
        }
        documents.push({
          id: obj.id || '',
          document_type_id: typeId,
          document_type: obj.document_type?.label || '',
          document: obj.document || '',
          document_name: obj.document_name || '',
        });
      }
    });
    request.documents = documents;

    if (isEdit) {
      dispatch(updateClient(match?.params?.id, data));
    } else {
      dispatch(createClient(data));
    }
  };

  // file upload event
  const changeEvent = async (data) => {
    if (
      data.field?.name === 'company_logo' &&
      data?.value?.target?.files &&
      data.value.target.files?.length > 0
    ) {
      const file = data?.value?.target?.files[0];
      var formData = new FormData();
      formData.append('folderName', 'company');
      formData.append('file', file);

      setFormData((data) => ({ ...data, isUploadDocument: true }));

      const response = await axios.post(apiList.fileUpload, formData, {
        headers: {},
        withCredentials: true,
      });

      const result = response?.data || {};
      if (result?.data) {
        setFormData((form) => ({
          ...form,
          values: {
            ...form.values,
            ...data.watch,
            company_logo: result?.data?.uploaded_url,
            original_name: result?.data?.original_name,
          },
          isUploadDocument: false,
        }));
      } else {
        dispatch(openSnackBarLayout('Company image is not uploaded', 'error', 1000));
      }
    }

    if (data.field?.name === 'remove') {
      setFormData((form) => ({
        ...form,
        values: { ...form.values, ...data.watch, company_logo: '' },
      }));
    }

    if (data?.value && data?.value?.__isNew__) {
      // if country id
      if (data?.field?.name === 'country_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, country_id: data?.value },
        }));

        createNewCountry(data?.value?.value);
      }

      // if state id
      if (data?.field?.name === 'state_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, state_id: data?.value },
        }));

        createNewState(data?.value?.value);
      }

      // if city id
      if (data?.field?.name === 'city_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, city_id: data?.value },
        }));

        createNewCity(data?.value?.value);
      }

      // if billing country id
      if (data?.field?.name === 'billing_country_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, billing_country_id: data?.value },
        }));

        createNewCountry(data?.value?.value);
      }

      // if billing state id
      if (data?.field?.name === 'billing_state_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, billing_state_id: data?.value },
        }));

        createNewState(data?.value?.value);
      }

      // if billing city id
      if (data?.field?.name === 'billing_city_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, billing_city_id: data?.value },
        }));

        createNewCity(data?.value?.value);
      }
    }
  };

  //  upload documents
  const UploadDocuments = (form, watch) => {
    const {
      formState: { errors },
      control,
    } = form;
    const { fields, append, remove, update } = useFieldArray({
      control,
      name: 'documentList',
    });

    // onchange event
    const onChangeDocType = (data, watch, fieldIndex) => {
      if (data?.value && data?.value?.__isNew__) {
        createNewDocumentType(data?.value?.value);
      }
    };

    const handleAddClick = () => {
      append({
        document_type: '',
        document: '',
        document_name: '',
      });
    };

    useEffect(() => {
      if (!isEdit && !watch?.documentList) {
        console.log('watch', watch);
        handleAddClick();
      }
    }, []);

    // Document upload
    const documentUploadChange = async (e, watch, index, list) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target?.files[0];
        var formData = new FormData();
        formData.append('folderName', 'documents');
        formData.append('file', file);
        setIsDocumentUploading((data) => ({ ...data, [list.id]: true }));

        const response = await axios.post(apiList.fileUpload, formData, {
          headers: {},
          withCredentials: true,
        });
        const result = response?.data || {};

        setIsDocumentUploading((data) => ({ ...data, [list.id]: false }));
        if (result?.data) {
          update(index, {
            ...watch?.documentList?.[index],
            document: result?.data?.uploaded_url,
            document_name: result?.data?.original_name,
          });
        } else {
          dispatch(openSnackBarLayout('Document is not uploaded', 'error', 1000));
        }
      }
    };

    // remove uploaded document
    const removeDocument = (watch, index) => {
      update(index, {
        ...watch?.documentList?.[index],
        document: '',
        document_name: '',
      });
    };

    // list document types based on the employee doc type id
    useEffect(() => {
      const documents = [];
      if (isEdit && !clientFindProcess && !clientFindError && clientFindData) {
        clientFindData?.data?.documents?.forEach((document) => {
          documents.push({
            id: document.id,
            document_type: {
              label: document.document_type,
              value: parseInt(document.document_type_id),
            },
            document: document.document,
            document_name: document.document_name,
          });
        });

        append(documents);

        if (clientFindData?.data?.documents?.length === 0) {
          handleAddClick();
        }
      }
    }, [clientFindProcess, clientFindError, clientFindData]);

    const getNonSelectedValue = (selectedValue) => {
      return formData.options?.document_types;
    };

    return (
      <>
        <h4 className="interview-label form-input-box document-view-label"> Upload Document(s)</h4>
        {fields.map((list, inputListIndex) => {
          return (
            <Grid
              container
              spacing={1}
              key={list.id}
              className={DocumentUploadInputs?.containerClass}
            >
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Box className={DocumentUploadInputs?.formElementClass}>
                  <FormControl className={DocumentUploadInputs?.formControlClass}>
                    <Controller
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          // create select
                          <CreatableSelect
                            inputRef={ref}
                            defaultValue={commonFn.getMultiValueFromId(
                              values?.[list?.valueId],
                              options?.[list?.optionsKey],
                            )}
                            isMulti={false}
                            isClearable={true}
                            value={value}
                            className={DocumentUploadInputs?.fieldClass}
                            name={name}
                            placeholder={DocumentUploadInputs?.documentTypePlaceholder}
                            isDisabled={isView ? true : ''}
                            options={getNonSelectedValue(value) || []}
                            onChange={(e) => {
                              onChange(e);
                              onChangeDocType({ value: e, field: list }, watch, inputListIndex);
                            }}
                          />
                        );
                      }}
                      control={control}
                      key={list.keyValue}
                      name={`documentList.${inputListIndex}.document_type`}
                      rules={{
                        required: false,
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>

              {/* file upload */}
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <Box className={DocumentUploadInputs?.formElementClass}>
                  {isDocumentUploading?.[list.id] === true && (
                    <div className="loader">
                      <CircularProgress />
                    </div>
                  )}
                  {(isDocumentUploading?.[list.id] === undefined ||
                    isDocumentUploading?.[list.id] === false) && (
                    <>
                      <input
                        className={DocumentUploadInputs.inputClass}
                        id={`data${list?.id}`}
                        type={DocumentUploadInputs?.documentUploadType}
                        onChange={(e) => {
                          documentUploadChange(e, watch, inputListIndex, list);
                        }}
                      />
                      {!watch?.documentList?.[inputListIndex]?.document && (
                        <label
                          htmlFor={`data${list?.id}`}
                          className={DocumentUploadInputs?.uploadTextClass}
                        >
                          <FileCopyIcon /> &nbsp; {DocumentUploadInputs?.documentPlaceholder}
                        </label>
                      )}
                      {watch?.documentList?.[inputListIndex]?.document && (
                        <div className="file-section">
                          <div
                            className={'delete-file'}
                            onClick={() => {
                              removeDocument(watch, inputListIndex);
                            }}
                          >
                            <HighlightOffIcon />
                          </div>
                          <a
                            href={watch?.documentList?.[inputListIndex]?.document}
                            target="_blank"
                            className={DocumentUploadInputs.uploadTextClass}
                          >
                            <FileCopyIcon />
                            {watch?.documentList?.[inputListIndex]?.document_name}
                          </a>
                        </div>
                      )}
                    </>
                  )}
                </Box>
              </Grid>

              {/* action buttons (add and remove) */}
              <Grid item>
                <Box className={DocumentUploadInputs?.formElementClass}>
                  {fields.length - 1 >= 1 && (
                    <Link className={'link-button'} onClick={() => remove(inputListIndex)}>
                      <RemoveCircle className={'interview-status-icon'} />
                    </Link>
                  )}
                  {fields.length - 1 === inputListIndex && (
                    <Link className={'link-button'} onClick={handleAddClick}>
                      <AddCircle className={'interview-status-icon'} />
                    </Link>
                  )}
                </Box>
              </Grid>
              {/* button ends */}
            </Grid>
          );
        })}
      </>
    );
  };
  // End document (SLA) upload

  return (
    <>
      <span key={match.params.id}>
        <TabCustom {...tabs} />
        {!isRouteChange && !formData?.inProgress && (
          <FormCustom
            {...formData}
            formSubmit={formSubmit}
            changeEvent={changeEvent}
            isLoading={isLoading}
            renderer={{ UploadDocuments }}
          />
        )}

        {(!clientFindError && clientFindProcess && isRouteChange) || (formData?.inProgress) && (
          <div className="loader">
            <CircularProgress />
          </div>
        )}
      </span>
    </>
  );
};
